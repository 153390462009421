// This file will contain constants that will be moved to
// config files once that framework has been implemented
// because we are not letting Subscribe handle the translations
// and we will make the site configurable via the TDB files
// so that we can support multiple Subscribe subscription systems

import _ from 'lodash';

export const productNames = {
  ATTMXCC_MOVIE: 'Rock this Joint',
  ATTMXCC_MUSIC: 'Life is a Freeway',
  ATTMXCC_TV: 'Season 1:Almost Lost',
  ATTMXCC_RINGTONE: 'New! Ringtone',
  ATTMXCC_GAME: 'Game of Trolls',
};

export const productDescriptions = {
  ATTMXCC_MOVIE: 'Story of success in challenging situations',
  ATTMXCC_MUSIC: 'Album: Real life </br> Artist: John Doe',
  ATTMXCC_TV: 'Season 1 of new groundbreaking TV series',
  ATTMXCC_RINGTONE: 'Most Popular Ringtone',
  ATTMXCC_GAME: 'Role Play game with drama',
};

export const productFeatures = {
  ATTMXCC_MOVIE: ['Story of success in challenging situations'],
  ATTMXCC_MUSIC: ['Album: Real life, Artist: John Doe'],
  ATTMXCC_TV: ['Season 1 of new groundbreaking TV series'],
  ATTMXCC_RINGTONE: ['Most Popular Ringtone'],
  ATTMXCC_GAME: ['Role Play game with drama'],
};

export const productTypes = {
  ATTMXCC_MOVIE: 'BASE',
  ATTMXCC_MUSIC: 'BASE',
  ATTMXCC_TV: 'BASE',
  ATTMXCC_RINGTONE: 'BASE',
  ATTMXCC_GAME: 'BASE',
};

export const productLogos = {
  ATTMXCC_MOVIE: 'ATTMXCC_MOVIE.png',
  ATTMXCC_MUSIC: 'ATTMXCC_MUSIC.png',
  ATTMXCC_TV: 'ATTMXCC_TV.png',
  ATTMXCC_RINGTONE: 'ATTMXCC_RINGTONE.png',
  ATTMXCC_GAME: 'ATTMXCC_GAME.png',
};

export const productSortOrder = {
  ATTMXCC_MOVIE: 1,
  ATTMXCC_MUSIC: 2,
  ATTMXCC_TV: 3,
  ATTMXCC_RINGTONE: 4,
  ATTMXCC_GAME: 5,
};

export const productSortOrderMobile = {
  ATTMXCC_MOVIE: 1,
  ATTMXCC_MUSIC: 2,
  ATTMXCC_TV: 3,
  ATTMXCC_RINGTONE: 4,
  ATTMXCC_GAME: 5,
};

export const getProductLogo = (productId) => _.get(productLogos, productId);

export const product = {
  productNames,
  productDescriptions,
  productTypes,
  productLogos,
  productFeatures,
  productSortOrder,
  productSortOrderMobile,
  getProductLogo,
};

export default product;
