import _ from 'lodash';
import React from 'react';
import {
  Menu,
  Button,
  Image,
} from 'semantic-ui-react';
import { withRouter } from 'react-router';
import { UserContext } from '../contexts/userContext';

import { getFrontendConfig, importAllImages } from '../utils/clientHelper';

const clientConfig = getFrontendConfig();

class LoginMenuAuth0 extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.handleLogoutClick = this.handleLogoutClick.bind(this);
    this.handleLoginClick = this.handleLoginClick.bind(this);
  }

  handleLoginClick = (event, { trigger: name }) => this.context.authService.login(name === 'signUpButton');

  handleLogoutClick = () => this.context.authService.logout();

  render() {
    if (_.get(clientConfig, 'authService.type', '') !== 'auth0') return null;

    const { disabled, images } = this.props;

    return this.context.authService.isAuthenticated() ? (
      <Menu borderless style={{
        maxWidth: '600px', border: 0, margin: 0, padding: 0
      }}>
        <Menu.Item style={{
          border: 0, margin: 0, padding: '20px'
        }}>Welcome, {this.context.authService.getUserName()}</Menu.Item>
        <Menu.Item position="right" style={{
          border: 0, margin: 0, padding: '20px'
        }}>
          <Button
            name="logoutButton"
            as="a" onClick={this.handleLogoutClick}
            disabled={disabled}
            secondary
          >
            Log Out
          </Button>
        </Menu.Item>
        <Image wrapped size="small" src={images['logo.png'].default} style={{ padding: '10px' }} />
      </Menu>
    ) : (
      <Menu borderless style={{
        maxWidth: '600px', border: 0, margin: 0, padding: 0
      }}>
        <Menu.Item position="right" disabled={disabled} style={{
          border: 0, margin: 0, padding: '20px'
        }}>
          <Button
            name="loginButton"
            as="a"
            disabled={disabled}
            onClick={this.handleLoginClick}
            secondary
          >
            Log In
          </Button>
        </Menu.Item>
        <Image wrapped size="small" src={images['logo.png'].default} style={{ padding: '10px' }} />
      </Menu>
    );
  }
}

class MarketingTopNav extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      errorMessage: '',
      hasAPIError: false,
      isModalOpen: false,
      images: importAllImages(),
    };
  }

  static getDerivedStateFromProps(props) {
    const pathname = _.get(props, 'location.pathname', '');

    if (pathname.indexOf('subscription') > 0) return { activeMenuItem: 'subscriptionMenuItem' };

    return { activeMenuItem: 'homeMenuItem' };
  }

  getMessageProps = () => ({
    hidden: !this.state.hasAPIError,
    visible: this.state.hasAPIError,
  });

  getAccountButtonProps = (isFixedMenuVisible, disabled) => ({
    isFixedMenuVisible,
    disabled,
  });

  render() {
    const { disabled, isFixedMenuVisible } = this.props;
    const { images } = this.state;
    return (

      <LoginMenuAuth0 isFixedMenuVisible={isFixedMenuVisible} disabled={disabled} images={images} />

    );
  }
}

export default withRouter(MarketingTopNav);
