// import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import {
  Modal, Grid, Segment, Image,
} from 'semantic-ui-react';

import SubscriptionDisplay from '../../components/SubscriptionDisplay';

import { purchasesReset } from '../../redux/purchases/actions';
import { closeModal } from '../../redux/modals/actions';

import { getFrontendConfig, importAllImages } from '../../utils/clientHelper';

class PurchaseProcessModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      images: importAllImages(),
    };

    this.onClose = this.onClose.bind(this);
  }

  onClose = (event) => {
    event.preventDefault();
  };

  render() {
    const { images } = this.state;
    return (
      <Modal closeOnEscape={false} closeOnDimmerClick={false} onClose={(e) => this.onClose(e)} open={true}>
        <Modal.Header>{getFrontendConfig('purchaseProcess.title')}</Modal.Header>
        <Modal.Content image>
          <Grid columns={2} stackable centered divided verticalAlign="top" padded="vertically">
            <Grid.Row>
              <Grid.Column width={8}>
                <SubscriptionDisplay />
              </Grid.Column>
              <Grid.Column width={8}>
                <Segment>
                  <Image wrapped size="medium" src={images['processing.png'].default} />
                  <Modal.Description>
                    {getFrontendConfig('purchaseProcess.description')}
                  </Modal.Description>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    purchasesResetProps: () => dispatch(purchasesReset()),
    closeModalProps: () => dispatch(closeModal()),
  };
}

export default connect(null, mapDispatchToProps)(PurchaseProcessModal);
