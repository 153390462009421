import _ from 'lodash';
import jsrsasign from 'jsrsasign';

import { getFrontendConfig, apiResponse } from '../../utils/clientHelper';

import history from '../../history';
import Auth0UniversalLock, { Auth0LoginTypeEnum } from './Auth0UniversalLock';

import messages from '../../clients/default/components/languages/en.json';

const jwt = _.get(jsrsasign, 'KJUR.jws.JWS');

// default auth service provided by rebar

const authServiceName = 'Auth0';

export default class Auth {
  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
    this.getIdToken = this.getIdToken.bind(this);
    this.renewSession = this.renewSession.bind(this);
    this.accountId = this.accountId.bind(this);
  }

  get auth0() {
    if (!this._auth0) return new Auth0UniversalLock(Auth0LoginTypeEnum.Login);

    return this._auth0;
  }

  getUniversalLoginLock = (loginType) => {
    this._auth0 = new Auth0UniversalLock(loginType);

    return this._auth0;
  };

  handleAuthentication = () => {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
        history.replace('/');
      }
      // if (err) history.replace('/');
      // alert(`Error: ${err.error}. Check the console for further details.`);
    });
  };

  getAccessToken = () => localStorage.getItem('accessToken');

  getIdToken = () => localStorage.getItem('idToken');

  getExpiration = () => localStorage.getItem('expiresAt');

  getUserName = () => localStorage.getItem('name');

  getEmail = () => localStorage.getItem('email');

  setSession = (authResult) => {
    // Set the time that the access token will expire at
    const expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
    const decoded = jwt.parse(authResult.idToken);

    localStorage.setItem('accessToken', authResult.accessToken);
    localStorage.setItem('idToken', authResult.idToken);
    localStorage.setItem('expiresAt', expiresAt);
    localStorage.setItem('name', decoded.payloadObj.name);
    localStorage.setItem('email', decoded.payloadObj.email);
  };

  renewSession = () => {
    this.auth0.checkSession({}, (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) this.setSession(authResult);
      else if (err) {
        this.logout();
        // // Logger
        // const { info } = console;
        // info(`Could not get a new token (${err.error}: ${err.error_description}).`);
      }
    });
  };

  logout = () => {
    // Remove tokens and expiry time
    localStorage.removeItem('accessToken');
    localStorage.removeItem('idToken');
    localStorage.removeItem('expiresAt');
    localStorage.removeItem('isLoggedIn');

    // navigate to the logout route
    const urlPath = window.location.host === 'localhost:3000' ? '' : getFrontendConfig('urlPath');
    this.auth0.logout({
      returnTo: `${window.location.protocol}//${window.location.host}${urlPath}`,
      client_id: `${getFrontendConfig('authService.clientId')}`,
    });
  };

  isAuthenticated = () => {
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAt = this.getExpiration();
    const idToken = this.getIdToken();

    if (!idToken) return false;

    if (new Date().getTime() > expiresAt && idToken) {
      this.renewSession();
      expiresAt = this.getExpiration();
    }

    return new Date().getTime() < expiresAt && idToken;
  };

  login = (isSignUp) => {
    const loginType = isSignUp ? Auth0LoginTypeEnum.SignUp : Auth0LoginTypeEnum.Login;

    this.getUniversalLoginLock(loginType);
    this.auth0.authorize({ action: loginType });
  };

  verifier = () => {
    let payload = { error: 401 };
    const _sessionToken = this.getIdToken();
    if (!_sessionToken) return payload;
    const sessionToken = _sessionToken.replace('Bearer ', '').replace('BEARER ', '');
    const decoded = jwt.parse(sessionToken);

    payload = _.get(decoded, 'payloadObj');
    if (!payload) return { authService: payload };
    _.set(payload, 'error', 0);
    _.set(payload, 'errorMessage', '');

    // set defaults
    _.set(payload, 'decoded', _.get(payload, 'sub', '') !== '');
    _.set(payload, 'verified', false);
    _.set(payload, 'expired', false);
    _.set(payload, 'authorized', false);
    _.set(payload, 'error', 0);

    const uid = _.get(payload, 'sub', '');
    _.set(payload, 'authServiceName', authServiceName);
    _.set(payload, 'uid', uid);
    _.set(payload, 'decoded', !!uid);

    // need to solve
    const expired = !uid;
    _.set(payload, 'expired', expired);
    _.set(payload, 'notExpired', !expired);

    // need to solve
    const verified = !!uid;
    _.set(payload, 'verified', verified);

    _.set(payload, 'authorized', verified && !expired);

    return { authService: payload };
  };

  // used in thunks

  isNotAuthenticated = () => (!this.isAuthenticated());

  sessionToken = () => this.getIdToken();

  errorMessage = () => {
    const errorCode = _.get(this.verifier(), 'error', 403);
    return apiResponse(errorCode, _.get(messages, `storefront.error.title.${errorCode}`));
  };

  accountId = () => _.get(this.verifier(), 'authService.uid');

  accountEncodeURI = () => encodeURI(this.accountId());
}
