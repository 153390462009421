import auth0 from 'auth0-js';

import { getFrontendConfig } from '../../utils/clientHelper';
/**
 * This enum tells the Auth0UniversalLogin lock whether it should be configured
 * for login or signup
 * @exports Auth0UniversalLogin/Auth0LoginTypeEnum
 */
export const Auth0LoginTypeEnum = { SignUp: 'signup', Login: 'login' };

/**
 * This is a singleton class that makes sure we instantiate a new
 * Auth0 WebAuth instance only when absolutely necessary. Multiple auth0 instances
 * cause havoc because of the passive login model
 * @class Auth0UniversalLogin
 * @exports Auth0UniversalLogin
 */
export default class Auth0UniversalLogin {
  /**
   *Creates an instance of Auth0UniversalLogin.
   * @param {Auth0LoginTypeEnum} [loginTypeEnum=Auth0LoginTypeEnum.Login] - Configure the UniversalLoginLock for login or signup
   * @memberof Auth0UniversalLogin
   */
  constructor(_loginTypeEnum = Auth0LoginTypeEnum.Login) {
    let loginTypeEnum = _loginTypeEnum;

    if (Auth0LoginTypeEnum.Login !== loginTypeEnum && Auth0LoginTypeEnum.SignUp !== loginTypeEnum) {
      // Logger
      const { info } = console;
      info(
        `An unexpected value was supplied to the Auth0UniversalLogin constructor.
         You need to make sure you are importing Auth0LoginTypeEnum from Auth0UniversalLock 
         when instantiating a new instance. The constructor will default to standard login`
      );
      loginTypeEnum = Auth0LoginTypeEnum.Login;
    }
    const urlPath = window.location.host === 'localhost:3000' ? '' : getFrontendConfig('urlPath');

    if (!Auth0UniversalLogin.instance || Auth0UniversalLogin.instance._loginType !== loginTypeEnum) {
      this._loginType = loginTypeEnum;
      this._auth0 = new auth0.WebAuth({
        domain: getFrontendConfig('authService.domain'),
        clientID: getFrontendConfig('authService.clientId'),
        redirectUri: `${window.location.protocol}//${window.location.host}${urlPath}/callback`,
        responseType: 'token id_token',
        scope: 'openid profile email',
        action: loginTypeEnum,
      });
      Auth0UniversalLogin.instance = this;
    }

    return Auth0UniversalLogin.instance._auth0;
  }
}
