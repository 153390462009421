export const ACTION_TYPES = {
  PURCHASES_RESET: 'purchases/RESET',
  PURCHASES_SUCCESS: 'purchases/PURCHASES_SUCCESS',
  PURCHASES_FAILURE: 'purchases/PURCHASES_FAILURE',
  PURCHASES_CREATE_ADD_BASE_PRODUCT: 'purchases/create/ADD_BASE_PRODUCT',
  PURCHASES_CREATE_ADD_PAYMENT_METHOD: 'purchases/create/ADD_PAYMENT_METHOD',
};

export const purchasesReset = () => ({
  type: ACTION_TYPES.PURCHASES_RESET,
});

export const purchasesSuccess = (results) => ({
  type: ACTION_TYPES.PURCHASES_SUCCESS,
  payload: results,
});

export const purchasesFailure = (error) => ({
  type: ACTION_TYPES.PURCHASES_FAILURE,
  payload: error,
});

export const createPurchaseAddPaymentAccount = (paymentMethod) => ({
  type: ACTION_TYPES.PURCHASES_CREATE_ADD_PAYMENT_METHOD,
  payload: paymentMethod,
});

export const createPurchaseAddProductAccount = ({ selectedProduct, accountId }) => ({
  type: ACTION_TYPES.PURCHASES_CREATE_ADD_BASE_PRODUCT,
  payload: { selectedProduct, accountId },
});
