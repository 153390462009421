import _ from 'lodash';

import { v4 as uuid } from 'uuid';

import { ACTION_TYPES } from './actions';

const emptyCreatePurchaseModel = () => ({
  object: 'Transaction',
  id: uuid().replace(/-/g, ''),
  account: { object: 'Account', id: '' },
  source_payment_method: { object: 'PaymentMethod', vid: '' },
  payment_method: '',
  currency: 'USD',
  items: [
    {
      object: 'TransactionItem',
      sku: '',
      name: '',
      quantity: 0,
      price: 0,
      product: '',
    },
  ],
  source_ip: '',
  policy: {
    min_chargeback_probability: 99,
    send_email_notification: 0,
  },
  to_be_captured: true,
});

export default function reducer(state = emptyCreatePurchaseModel(), { type, payload }) {
  const newState = { ...state };
  switch (type) {
    case ACTION_TYPES.PURCHASES_RESET:
      return emptyCreatePurchaseModel();

    case ACTION_TYPES.PURCHASES_SUCCESS:
      return { ...state, ...payload };

    case ACTION_TYPES.TRANSACTIONS_FAILURE:
      return { ...state, ...payload.error };

    case ACTION_TYPES.PURCHASES_CREATE_ADD_PAYMENT_METHOD:
      _.set(newState, 'source_payment_method.vid', _.get(payload, 'vid', ''));
      _.set(newState, 'payment_method', payload);
      return newState;

    case ACTION_TYPES.PURCHASES_CREATE_ADD_BASE_PRODUCT:
      _.set(newState, 'account.id', _.get(payload, 'accountId', ''));
      _.set(newState, 'items.0.sku', _.get(payload, 'selectedProduct.id', ''));
      _.set(newState, 'items.0.name', _.get(payload, 'selectedProduct.productName', ''));
      _.set(newState, 'items.0.quantity', 1);
      _.set(newState, 'items.0.price', _.get(payload, 'selectedProduct.amount', ''));
      _.set(newState, 'items.0.product', _.get(payload, 'selectedProduct', ''));
      return newState;

    default:
      return state;
  }
}
