// import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import {
  Button, Modal, Grid, Segment, Image
} from 'semantic-ui-react';
import SubscriptionDisplay from '../../components/SubscriptionDisplay';

import { purchasesReset } from '../../redux/purchases/actions';
import { closeModal } from '../../redux/modals/actions';

import { getFrontendConfig, importAllImages } from '../../utils/clientHelper';

class PurchaseSuccessModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      images: importAllImages(),
    };

    this.onClose = this.onClose.bind(this);
  }

  onClose = () => {
    const { closeModalProps, purchasesResetProps } = this.props;
    purchasesResetProps();
    closeModalProps();
  };

  render() {
    const { images } = this.state;
    return (
      <Modal closeOnEscape={false} closeOnDimmerClick={false} onClose={() => this.onClose()} open={true}>
        <Modal.Header>{getFrontendConfig('purchaseSuccess.title')}</Modal.Header>
        <Modal.Content image>
          <Grid columns={2} stackable centered divided verticalAlign="top" padded="vertically">
            <Grid.Row>
              <Grid.Column width={8}>
                <SubscriptionDisplay />
              </Grid.Column>
              <Grid.Column width={8}>
                <Segment>
                  <Image wrapped size="medium" src={images['success.png'].default} />
                  <Modal.Description>
                    {getFrontendConfig('purchaseSuccess.description')}
                  </Modal.Description>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button
            icon
            size="large"
            as="a"
            href={getFrontendConfig('afterPurchase.link')}
            className={'active b'}
            circular
            content={`${getFrontendConfig('afterPurchase.label', 'Purchase')}`}
            onClick={() => this.onClose()}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

// function mapStateToProps(store) {
//   return {
//     createSubscriptionModel: store.subscriptions.createSubscriptionModel,
//   };
// }

function mapDispatchToProps(dispatch) {
  return {
    purchasesResetProps: () => dispatch(purchasesReset()),
    closeModalProps: () => dispatch(closeModal()),
  };
}

export default connect(null, mapDispatchToProps)(PurchaseSuccessModal);
