import React from 'react';
import _ from 'lodash';
import {
  Segment,
  Button,
  Label,
  Item,
} from 'semantic-ui-react';

import history from '../history';

import { getFrontendConfig, importAllImages, formatCurrency } from '../utils/clientHelper';

const selectProducts = (products, productType) => {
  const filterProducts = _.filter(products, (product) => product.productType === productType);
  return _.sortBy(filterProducts, ['sort']);
};

class ProductContainer extends React.Component {
  constructor(props) {
    super(props);
    this.buttonRefs = {};
    this.state = {
      activeSubscription: null,
      enableNextButton: false,
      images: importAllImages(),
    };
  }

  getFeatureColumns = (items) => {
    if (!_.isArray(items)) {
      return ('No Products');
    }
    // no longer processing an array
    return items[0];
  };

  getAttrs = () => {
    const cssClasses = {};

    if (this.props.condensed) cssClasses.className = 'condensed';

    return cssClasses;
  };

  getBackgroundColor = (control) => {
    let className = '';

    if (this.props.rowColor === 'darkSlate') {
      switch (control.ref.nodeName) {
        case 'BUTTON':
        case 'LABEL':
          className = 'element--background-color-grey';
          break;
        default:
          className = 'element--background-color-slate-dark';
          break;
      }
    }

    return className;
  };

  handleProductClick = (event, data) => {
    if (_.get(data, 'product_id')) {
      const {
        isUpdatingSubscription, productType, currentPurchase, flowType
      } = this.props;

      this.setState({ activeSubscription: _.get(data, 'product_id') });

      if (this.props.handleProductOnClick) this.props.handleProductOnClick(event, data);

      const hasEnhancement = getFrontendConfig('EnhancementContainer.enabled');

      const pathType = flowType === 'createPurchase' ? 'purchase' : 'subscription';
      let path = `/${pathType}/payment`;

      if (isUpdatingSubscription) path = `/${pathType}/summary`;
      if (hasEnhancement) path = `/${pathType}/enhancement`;

      history.replace(path, {
        flowType,
        isUpdatingSubscription: false,
        currentPurchase,
        productType,
        productId: _.get(data, 'product_id')
      });
    }
  };

  render() {
    const { products, productType } = this.props;
    const { images } = this.state;

    if (!products) return null;

    const displayProducts = selectProducts(products, productType);

    return (
      <Segment style={{ maxWidth: '600px' }}>
        <Item.Group relaxed divided>
          {displayProducts.map((product, idx) => (
            <Item key={idx} id={`product-item-${product.id}`} className='ui segment'>
              <Item.Image size='tiny' src={images[product.logo].default} />
              <Item.Content verticalAlign='middle'>
                <Item.Header>{product.productName}</Item.Header>
                <Item.Description>{this.getFeatureColumns(product.productFeatures)}</Item.Description>
                <Item.Extra>
                  <Label className='header'>{product ? formatCurrency(product.amount) : 'contact for price'}</Label>
                  <Button
                    floated='right'
                    product_id={product.id}
                    ref={(el) => { this.buttonRefs[`${product.id}`] = el; return true; }}
                    onClick={this.handleProductClick}
                    content="Comprar"
                  />
                </Item.Extra>
              </Item.Content>
            </Item>

          ))}
        </Item.Group>
      </Segment>
    );
  }
}

export default ProductContainer;
