import _ from 'lodash';
import React from 'react';

import {
  Grid,
  Container,
  Item,
  Label,
} from 'semantic-ui-react';

import { importAllImages, formatCurrency } from '../utils/clientHelper';

class ProductDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      images: importAllImages(),
    };
  }

  getFeatureColumns = (items) => {
    if (!_.isArray(items)) {
      return (
        <Grid.Row key={`${10}`}>
          {' '}
        </Grid.Row>
      );
    }

    return items.map((feature, index) => (
      <Grid.Row key={`${index + 10}`}>
        {feature}
      </Grid.Row>
    ));
  };

  getAttrs = () => {
    const cssClasses = {};

    if (this.props.condensed) cssClasses.className = 'condensed';

    return cssClasses;
  };

  getBackgroundColor = (control) => {
    let className = '';

    if (this.props.rowColor === 'darkSlate') {
      switch (control.ref.nodeName) {
        case 'BUTTON':
        case 'LABEL':
          className = 'element--background-color-grey';
          break;
        default:
          className = 'element--background-color-slate-dark';
          break;
      }
    }

    return className;
  };

  render() {
    const { displayProduct: product } = this.props;
    const { images } = this.state;

    if (!product) return null;
    const idx = 0;
    return (
      <Container style={{ maxWidth: '600px' }}>
        <Item.Group relaxed divided>
          <Item key={idx} id={`product-item-${product.id}`} className='ui circular'>
            <Item.Image size='tiny' src={images[product.logo].default} />
            <Item.Content verticalAlign='middle'>
              <Item.Header>{product.productName}</Item.Header>
              <Item.Description>{this.getFeatureColumns(product.productFeatures)}</Item.Description>
              <Item.Extra>
                <Label className='header'>{product ? formatCurrency(product.amount) : 'contact for price'}</Label>
              </Item.Extra>
            </Item.Content>
          </Item>
        </Item.Group>
      </Container>
    );
  }
}

export default ProductDisplay;
