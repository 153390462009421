import React from 'react';

export const UserContext = React.createContext({
  locale: 'en',
  theme: '',
  apiLock: () => { },
  authService: () => { },
  isAuthenticated: () => { },
});

export default UserContext;
