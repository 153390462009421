export const ACTION_TYPES = {
  ACCOUNTS_REQUEST: 'accounts/REQUEST',
  ACCOUNTS_REQUEST_SUCCESS: 'accounts/ACCOUNTS_SUCCESS',
  ACCOUNTS_REQUEST_FAILURE: 'accounts/ACCOUNTS_FAILURE',
  ACCOUNTS_UPDATE_PAYMENT_METHOD: 'accounts/update/PAYMENT_METHOD',
};

export const accountsRequest = () => ({
  type: ACTION_TYPES.ACCOUNTS_REQUEST,
});

export const accountsRequestSuccess = (results) => ({
  type: ACTION_TYPES.ACCOUNTS_REQUEST_SUCCESS,
  payload: results,
});

export const accountsRequestFailure = (error) => ({
  type: ACTION_TYPES.ACCOUNTS_REQUEST_FAILURE,
  payload: error,
});

export const accountsUpdatePaymentMethod = (paymentMethods) => ({
  type: ACTION_TYPES.ACCOUNTS_UPDATE_PAYMENT_METHOD,
  payload: paymentMethods,
});
