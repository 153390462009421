import React from 'react';

export const pageHeader = () => (
  <div id="top-navigation">
    <div className="nav Animate" data-uk-sticky="cls-active: uk-box-shadow-medium; top: 100vh;">
      <div className="uk-container">
        <nav className="uk-navbar uk-navbar-container uk-navbar-transparent" data-uk-navbar="true">
          <div className="uk-navbar-left">
            <div className="uk-navbar-item uk-padding-remove-horizontal uk-margin-left@m">
              <a aria-current="page" className="logo" href="/">
                <img
                  className="uk-logo logo"
                  src="/static/vindicia-logo-120c9671788e1122c7c4c6f39957fde7.png"
                  alt="Vindicia logo"
                />
              </a>
            </div>
          </div>
          <div className="uk-navbar-right uk-light">
            <div className="uk-grid uk-grid-collapse uk-child-width-1-1" data-uk-grid="true">
              <p
                id="customer-portal-link"
                style={{ 'min-height': '8px' }}
                className="uk-navbar-item uk-flex-right uk-margin-small-top uk-margin-remove-bottom uk-padding-remove uk-visible@m uk-visible"
              >
                <a href="https://secure.vindicia.com/">Customer Portal</a>
              </p>
              <div>
                <ul className="uk-navbar-nav uk-visible@m uk-flex-right">
                  <li>
                    <div className="uk-navbar-item">Solutions</div>
                    <div
                      className="uk-navbar-dropdown"
                      data-uk-drop="animation: uk-animation-slide-bottom-medium; offset: -15"
                    >
                      <ul className="uk-nav uk-navbar-dropdown-nav">
                        <li>
                          <a href="/marketone">MarketONE</a>
                        </li>
                        <li className="uk-margin-left">
                          <a href="/products/rebar">Subscribe</a>
                        </li>
                        <li className="uk-margin-left">
                          <a href="/products/connect">Connect</a>
                        </li>
                        <li>
                          <a href="/products/retain">Retain</a>
                        </li>
                        <li>
                          <a href="/products/subscription-intelligence">Subscription Intelligence</a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div className="uk-navbar-item">Partners</div>
                    <div
                      className="uk-navbar-dropdown"
                      data-uk-drop="animation: uk-animation-slide-bottom-medium; offset: -15"
                    >
                      <ul className="uk-nav uk-navbar-dropdown-nav">
                        <li>
                          <a href="/company/partners">Our Partners</a>
                        </li>
                        <li>
                          <a href="/company/become-partner">Become a Partner</a>
                        </li>
                        <li>
                          <a href="/company/refer-business">Refer a Business</a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div className="uk-navbar-item">Resources</div>
                    <div
                      className="uk-navbar-dropdown"
                      data-uk-drop="animation: uk-animation-slide-bottom-medium; offset: -15"
                    >
                      <ul className="uk-nav uk-navbar-dropdown-nav">
                        <li>
                          <a href="/resources/materials">Educational Materials</a>
                        </li>
                        <li>
                          <a href="/webinars">On-Demand Webinars</a>
                        </li>
                        <li>
                          <a href="/clients">Our Clients</a>
                        </li>
                        <li>
                          <a href="/roi">ROI Calculator</a>
                        </li>
                        <li className="uk-nav-header">Technical Center</li>
                        <li className="uk-margin-left">
                          <a href="https://knowledge.vindicia.com/">Knowledge Base</a>
                        </li>
                        <li className="uk-margin-left">
                          <a href="/technical-center/vindicia-user-guides">Vindicia User Guides</a>
                        </li>
                        <li className="uk-margin-left">
                          <a href="/technical-center/customer-deployment-solutions">Client Services</a>
                        </li>
                        <li className="uk-margin-left">
                          <a href="/technical-center/vindicia-glossary">Glossary</a>
                        </li>
                        <li className="uk-margin-left">
                          <a href="/technical-center/faq/vindicia-subscribe-faq">FAQ</a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div className="uk-navbar-item">About</div>
                    <div
                      className="uk-navbar-dropdown"
                      data-uk-drop="animation: uk-animation-slide-bottom-medium; offset: -15"
                    >
                      <ul className="uk-nav uk-navbar-dropdown-nav">
                        <li className="uk-nav-header">News/Events</li>
                        <li className="uk-margin-left">
                          <a href="/company/vindicia-in-the-news">In The News</a>
                        </li>
                        <li className="uk-margin-left">
                          <a href="/company/upcoming-events">Upcoming Events</a>
                        </li>
                        <li className="uk-margin-left">
                          <a href="/company/press-releases">Press Releases</a>
                        </li>
                        <li className="uk-nav-header">Who We Are</li>
                        <li className="uk-margin-left">
                          <a href="/company/about-vindicia">About Vindicia</a>
                        </li>
                        <li className="uk-margin-left">
                          <a href="/company/team">Team</a>
                        </li>
                        <li className="uk-margin-left">
                          <a href="/company/awards">Awards</a>
                        </li>
                        <li className="uk-margin-left">
                          <a href="/company/careers">Careers</a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <a href="/blog">Blog</a>
                  </li>
                  <li>
                    <a className="uk-padding-remove-right" href="/contact-us">
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <a
              style={{ 'min-height': '20px', padding: '4px', color: '#000' }}
              className="uk-navbar-toggle uk-hidden@m uk-background-default uk-padding-small uk-border-rounded"
              href="#offcanvas-nav"
              data-uk-toggle="target: #offcanvas-nav"
            >
              <span className="uk-float-right" data-uk-icon="icon: menu; ratio: 1.2" />
            </a>
          </div>
        </nav>
      </div>
    </div>
  </div>
);

export default pageHeader;
