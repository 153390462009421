import React from 'react';
import { createMedia } from '@artsy/fresnel';
import DesktopLayout from './DesktopLayout';
import MobileLayout from './MobileLayout';

const { MediaContextProvider, Media } = createMedia({
  // breakpoints values can be either strings or integers
  breakpoints: {
    sm: 0,
    md: 768,
    lg: 1192,
    xl: 1192,
  },
});

class ResponsiveLayout extends React.Component {
  render() {
    const { showHomepageHeading, disabled, children } = this.props;

    return (
      <MediaContextProvider>
        <Media lessThan="md">
          <MobileLayout showHomepageHeading={!!showHomepageHeading}>
            {children}
          </MobileLayout>
        </Media>
        <Media greaterThanOrEqual="md">
          <DesktopLayout showHomepageHeading={!!showHomepageHeading} disabled={!!disabled}>
            {children}
          </DesktopLayout>
        </Media>
      </MediaContextProvider>
    );
  }
}

export default ResponsiveLayout;
