import AuthServiceAuth0 from './auth0';
import { Auth0LoginTypeEnum } from './Auth0UniversalLock';
// import { getFrontendConfig } from '../../utils/clientHelper';

export default class AuthSvc {
  constructor() {
    // if (getFrontendConfig('authService.type') === 'gigya') return { ...new AuthServiceGigya() };

    return {
      ...new AuthServiceAuth0(),
      Auth0LoginType: Auth0LoginTypeEnum,
    };
  }
}
