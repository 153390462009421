import _ from 'lodash';
import React from 'react';
import {
  Form,
  // Segment,
  // Label,
  // Checkbox,
  // Input
} from 'semantic-ui-react';

// const inital = {
//   sameAddress: true,
//   shippingAddressLine1: '',
//   shippingAddressCity: '',
//   shippingAddressDistrict: '',
//   shippingAddressPostalCode: '',
// };

class ShippingAddress extends React.Component {
  render() {
    const {
      sameAddress,
      shippingAddressLine1,
      shippingAddressCity,
      shippingAddressDistrict,
      shippingAddressPostalCode,
    } = _.get(this.props, 'shippingAddress');
    const showAddress = () => {
      if (sameAddress) return 'hidden';
      return '';
    };
    return (
      <Form>
        <Form.Checkbox
          id='sameAddress'
          name='sameAddress'
          label='Shipping Address same as Billing Address'
          checked={sameAddress}
          onChange={this.props.handleCheckBox}
        />
        <Form.Input
          id='shippingAddressLine1'
          name='shippingAddressLine1'
          label='Shipping Address'
          placeholder='Shipping Address'
          value={shippingAddressLine1}
          onChange={this.props.handleChange}
          disabled={sameAddress}
          className={showAddress()}
        />
        <Form.Input
          id='shippingAddressCity'
          name='shippingAddressCity'
          label='Shipping City'
          placeholder='Shipping City'
          value={shippingAddressCity}
          onChange={this.props.handleChange}
          disabled={sameAddress}
          className={showAddress()}
        />
        <Form.Input
          id='shippingAddressDistrict'
          name='shippingAddressDistrict'
          label='Shipping State'
          placeholder='Shipping State'
          value={shippingAddressDistrict}
          onChange={this.props.handleChange}
          className={showAddress()}
          disabled={sameAddress}
        />
        <Form.Input
          id='shippingAddressPostalCode'
          name='shippingAddressPostalCode'
          label='Shipping Zip'
          placeholder='Shipping Zip'
          value={shippingAddressPostalCode}
          onChange={this.props.handleChange}
          className={showAddress()}
          disabled={sameAddress}
        />

      </Form>
    );
  }
}

export default ShippingAddress;
