import React from 'react';
import { Segment, Dimmer, Loader } from 'semantic-ui-react';

class SimpleLoader extends React.Component {
  render() {
    const {
      message, active, description, children
    } = this.props;

    return active ? (
      <Dimmer.Dimmable as={Segment} dimmed={active}>
        <Dimmer active={!!active}>
          <Loader size="massive" indeterminate active>
            <p>{message || 'Loading'}</p>
            <p>{description || ''}</p>
          </Loader>
        </Dimmer>
      </Dimmer.Dimmable>
    ) : (
      <>{children}</>
    );
  }
}

export default SimpleLoader;
