/* eslint-disable class-methods-use-this */
import React from 'react';

class PurchaseNoModal extends React.Component {
  render() {
    return <></>;
  }
}

export default (PurchaseNoModal);
