import React from 'react';
import ResponsiveLayout from './ResponsiveLayout';

export default class CoreLayout extends React.Component {
  render() {
    const { children, disabled } = this.props;

    return (
      <ResponsiveLayout showHomepageContainer={false} disabled={!!disabled}>
        {children}
      </ResponsiveLayout>
    );
  }
}
