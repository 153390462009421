// https://github.com/markerikson/project-minimek/

import React from 'react';
import { connect } from 'react-redux';

import PurchaseSuccessModal from './PurchaseSuccessModal';
import PurchaseProcessModal from './PurchaseProcessModal';
import PurchaseFailureModal from './PurchaseFailureModal';
import PurchaseNoModal from './PurchaseNoModal';

const modalComponentLookupTable = {
  PurchaseNoModal,
  PurchaseSuccessModal,
  PurchaseProcessModal,
  PurchaseFailureModal,
};

class ModalManager extends React.Component {
  render() {
    const { modals } = this.props;
    const { modalType, modalProps = {} } = modals;
    const ModalComponent = modalComponentLookupTable[modalType];

    const renderedModal = <ModalComponent {...modalProps} />;

    return <span>{renderedModal}</span>;
  }
}

function mapStateToProps(store) {
  return {
    modals: store.modals,
    products: store.products,
  };
}

export default connect(mapStateToProps)(ModalManager);
