// https://github.com/markerikson/project-minimek/

export const ACTION_TYPES = {
  MODAL_OPEN: 'modals/OPEN',
  MODAL_CLOSE: 'modals/CLOSE',
};

export function openModal(modalType, modalProps) {
  return {
    type: ACTION_TYPES.MODAL_OPEN,
    payload: { modalType, modalProps },
  };
}

export function closeModal() {
  return {
    type: ACTION_TYPES.MODAL_CLOSE,
  };
}
