import _ from 'lodash';
import axios from 'axios';
import { v4 as uuid } from 'uuid';

import {
  subscriptionsRequest,
  subscriptionsFailure,
  subscriptionsSuccess,
  subscriptionsDefault,
  createSubscriptionWithBaseProduct,
  createSubscriptionAddEnhancementRequest,
  createSubscriptionRemoveEnhancementRequest,
  createSubscriptionCreateAutoBill,
  cancelSubscriptionRequest,
  cancelSubscriptionSuccess,
  cancelSubscriptionFailure,
  updateSubscriptionRemoveEnhancementRequest,
  updateSubscriptionAddEnhancementRequest,
  updateSubscriptionWithBaseProduct,
  updateSubscriptionUpdateAutoBill,
} from './actions';

import { getFrontendConfig } from '../../utils/clientHelper';
import AuthService from '../../services/authService';

const authService = new AuthService();

const axiosClient = axios.create({
  headers: { Accept: 'application/json' },
  responseType: 'json',
  baseURL: `${getFrontendConfig('billingApi')}`,
});

export function cancelSubscription(subscriptionId) {
  return (dispatch) => {
    if (authService.isNotAuthenticated()) return Promise.reject(dispatch(cancelSubscriptionFailure(authService.errorMessage())));

    dispatch(cancelSubscriptionRequest);

    return axiosClient
      .post(`/api/subscriptions/${subscriptionId}/actions/cancel?settle=true&disentitle=true`, null, {
        headers: {
          Authorization: `Bearer ${authService.sessionToken()}`,
        },
      })
      .then((response) => {
        if (_.get(response, 'data.success', false) === false) throw new Error('Error cancelling subscription');

        return dispatch(cancelSubscriptionSuccess(_.get(response, 'data.data', '')));
      })
      .catch((err) => dispatch(cancelSubscriptionFailure(err)));
  };
}

export function createSubscription(createSubscriptionObject, dryrun = false) {
  return (dispatch) => {
    if (authService.isNotAuthenticated()) return Promise.reject(dispatch(subscriptionsFailure(authService.errorMessage())));
    // TODO:determine correct bill plan in middleware
    // const baseProduct = _.find(createSubscriptionObject.items, { product: { productType: 'BASE' } });
    // const billingPlan = _.get(baseProduct, `product.productBillingPlan.${dryrun ? 'dryrun' : 'new'}`);

    const shippingAddress = (subscription) => {
      if (_.get(subscription, 'account.shippingAddress.sameAddress')) {
        return { sameAddress: true };
      }
      return {
        line1: _.get(subscription, 'shippingAddress.shippingAddressLine1', ''),
        line2: _.get(subscription, 'shippingAddress.shippingAddressLine2', ''),
        line3: _.get(subscription, 'shippingAddress.shippingAddressLine3', ''),
        city: _.get(subscription, 'shippingAddress.shippingAddressCity', ''),
        district: _.get(subscription, 'shippingAddress.shippingAddressDistrict', ''),
        postal_code: _.get(subscription, 'shippingAddress.shippingAddressPostalCode', ''),
      };
    };

    const accountId = `${getFrontendConfig('accountPrefix')}${uuid().replaceAll('-', '')}`; // creates a new account with every Subscription
    const email = _.get(createSubscriptionObject, 'account.email', '');
    const emailType = email === '' ? '' : _.get(createSubscriptionObject, 'account.email_type', 'html');
    const subscriptionObject = {
      object: 'Subscription',
      id: _.get(createSubscriptionObject, 'id'),
      account: {
        object: 'Account',
        id: accountId,
        email,
        email_type: emailType,
        shipping_address: shippingAddress(createSubscriptionObject),
      },
      billing_plan: {
        object: 'BillingPlan',
        id: 'BP_1DAY',
      },
      payment_method: {
        object: 'PaymentMethod',
        vid: _.get(createSubscriptionObject, 'payment_method.vid'),
      },
      currency: _.get(createSubscriptionObject, 'currency'),
      starts: _.get(createSubscriptionObject, 'starts'),
      items: _.map(createSubscriptionObject.items, (item) => ({
        object: 'SubscriptionItem',
        id: item.id,
        product: {
          object: 'Product',
          id: item.product.id,
        },
      })),
      minimum_commitment: '0',
      policy: {
        ignore_cvn_policy: 1,
        ignore_avs_policy: 1,
        min_chargeback_probability: 100,
        immediate_auth_failure_policy: 'doNotSaveAutoBill',
        validate_for_future_payment: 0,
      },
      description: _.get(createSubscriptionObject, 'id'),
    };
    const url = dryrun ? '/api/subscriptions?dryrun=true' : '/api/subscriptions';
    return axiosClient
      .post(url, subscriptionObject, {
        headers: {
          Authorization: `Bearer ${authService.sessionToken()}`,
        },
      })
      .then((response) => {
        if (_.get(response, 'data.success', false) === false) {
          throw new Error(_.get(response, 'data.errorMessage', 'Unknown Error'));
        }
        // check dryrun before committing changes to local store
        if (!dryrun) return dispatch(createSubscriptionCreateAutoBill(_.get(response, 'data.data', '')));
        return true;
      })
      .catch((err) => {
        dispatch(subscriptionsFailure(err.message));

        throw new Error(err.message);
      });
  };
}

export function updateSubscription(subscriptionObjectModel) {
  return (dispatch) => {
    if (authService.isNotAuthenticated()) return Promise.reject(dispatch(subscriptionsFailure(authService.errorMessage())));

    // TODO: minic createSubscription
    const subscriptionObject = _.assign(subscriptionObjectModel);
    delete subscriptionObject.billing_plan;

    return axiosClient
      .post(
        `/api/subscriptions/${subscriptionObject.id}?bill_prorated_period=true&effective_date=today`,
        subscriptionObject,
        {
          headers: {
            Authorization: `Bearer ${authService.sessionToken()}`,
          },
        }
      )
      .then((response) => {
        if (_.get(response, 'data.success', false) === false) throw new Error('Error creating subscription');

        return dispatch(updateSubscriptionUpdateAutoBill(_.get(response, 'data.data', '')));
      })
      .catch((err) => dispatch(subscriptionsFailure(err)));
  };
}

export function updateSubscriptionAddEnhancement(subscriptionItem, selectedProductId) {
  return (dispatch) => {
    if (authService.isNotAuthenticated()) return Promise.reject(dispatch(subscriptionsFailure(authService.errorMessage())));

    const accountId = authService.accountId();

    return dispatch(
      updateSubscriptionAddEnhancementRequest({
        subscriptionItem,
        selectedProductId,
        accountId,
      })
    );
  };
}

export function updateSubscriptionRemoveEnhancement(subscriptionItem, selectedProductId) {
  return (dispatch) => {
    if (authService.isNotAuthenticated()) return Promise.reject(dispatch(subscriptionsFailure(authService.errorMessage())));

    const accountId = authService.accountId();

    return dispatch(
      updateSubscriptionRemoveEnhancementRequest({
        subscriptionItem,
        selectedProductId,
        accountId,
      })
    );
  };
}

export function createSubscriptionAddEnhancement(selectedProductId) {
  return (dispatch) => {
    if (authService.isNotAuthenticated()) return Promise.reject(dispatch(subscriptionsFailure(authService.errorMessage())));

    const accountId = authService.accountId();

    return dispatch(
      createSubscriptionAddEnhancementRequest({
        selectedProductId,
        accountId,
      })
    );
  };
}

export function createSubscriptionRemoveEnhancement(selectedProductId) {
  return (dispatch) => {
    if (authService.isNotAuthenticated()) return Promise.reject(dispatch(subscriptionsFailure(authService.errorMessage())));

    const accountId = authService.accountId();

    return dispatch(
      createSubscriptionRemoveEnhancementRequest({
        selectedProductId,
        accountId,
      })
    );
  };
}

export function updateSubscriptionAddBaseProduct(selectedProductId, currentBaseSubscriptionItem) {
  return (dispatch) => {
    if (authService.isNotAuthenticated()) return Promise.reject(dispatch(subscriptionsFailure(authService.errorMessage())));

    const accountId = authService.accountId();

    return dispatch(
      updateSubscriptionWithBaseProduct({
        selectedProductId,
        currentBaseSubscriptionItem,
        accountId,
      })
    );
  };
}

export function createSubscriptionAddBaseProduct(selectedProduct) {
  return (dispatch) => {
    if (authService.isNotAuthenticated()) return Promise.reject(dispatch(subscriptionsFailure(authService.errorMessage())));

    const accountId = authService.accountId();
    const email = authService.getEmail();

    return dispatch(
      createSubscriptionWithBaseProduct({
        selectedProduct,
        accountId,
        email
      })
    );
  };
}

export function getSubscriptions() {
  return (dispatch) => {
    if (authService.isNotAuthenticated()) {
      dispatch(subscriptionsFailure(authService.errorMessage()));
      return dispatch(subscriptionsDefault());
    }

    dispatch(subscriptionsRequest());

    return axiosClient
      .get(`/ api / subscriptions ? account = ${authService.accountEncodeURI()}`, {
        headers: { Authorization: `Bearer ${authService.sessionToken()} ` },
      })
      .then((response) => {
        if (_.get(response, 'data.success', false) === false) throw new Error('error getting subscription for account');
        return dispatch(subscriptionsSuccess(_.get(response, 'data.data', '')));
      })
      .catch((err) => {
        dispatch(subscriptionsFailure(err));
        return dispatch(subscriptionsDefault());
      });
  };
}
