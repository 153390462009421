import _ from 'lodash';
import React from 'react';
import {
  Segment, Form, Icon, Modal, Checkbox,
} from 'semantic-ui-react';
import { getFrontendConfig } from '../utils/clientHelper';

class TermsAndConditions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showingTermsAndConditions: _.get(props, 'showingTermsAndConditions', false),
    };

    this.toggleTermsAndConditions = this.toggleTermsAndConditions.bind(this);
    this.termsAndConditionsModal = this.termsAndConditionsModal.bind(this);
  }

  toggleTermsAndConditions() {
    this.setState({
      showingTermsAndConditions: !this.state.showingTermsAndConditions,
    });
  }

  termsAndConditionsModal() {
    const { showingTermsAndConditions } = this.state;

    return (
      <Modal
        onClose={this.toggleTermsAndConditions}
        closeIcon={<Icon name="close" />}
        closeOnDimmerClick
        open={showingTermsAndConditions}
        trigger={
          <Checkbox
            id='termsAndConditions'
            onChange={this.props.onAgree}
            required
            value={this.props.agreedToTermsAndConditions}
            label={
              <label>
                {getFrontendConfig('TermsAndConditions.Label')}
                <a href="#t-and-c" onClick={this.toggleTermsAndConditions}>
                  {getFrontendConfig('TermsAndConditions.Link')}
                </a>
              </label>
            }
          />
        }
      >
        <Modal.Header>Terms And Conditions</Modal.Header>
        <Modal.Content>
          <Segment raised>
            <Form>
              <Form.TextArea readOnly rows={25} value={getFrontendConfig('TermsAndConditions.Text')} />
            </Form>
          </Segment>
        </Modal.Content>
      </Modal>
    );
  }

  render() {
    const termsAndConditionsModal = this.termsAndConditionsModal();
    return (
      <Form>
        <Form.Field>{termsAndConditionsModal}</Form.Field>
      </Form>
    );
  }
}

export default TermsAndConditions;
