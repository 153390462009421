import { ACTION_TYPES } from './actions';

const initialState = {};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case ACTION_TYPES.ACCOUNTS_REQUEST_SUCCESS:
      return { ...payload };
    case ACTION_TYPES.ACCOUNTS_REQUEST_FAILURE:
      return { ...payload.errorMessage };
    case ACTION_TYPES.ACCOUNTS_UPDATE_PAYMENT_METHOD:
      return { ...state, ...payload };
    default:
      return state;
  }
}
