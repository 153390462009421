import _ from 'lodash';
import axios from 'axios';

import { productsRequest, productsFailure, productsSuccess } from './actions';
import { getFrontendConfig, productHelper } from '../../utils/clientHelper';

const {
  productNames,
  productTypes,
  productDescriptions,
  productFeatures,
  productBillingPlans,
  productTotalLine,
  productSortOrder,
  productSortOrderMobile,
  getProductLogo,
} = productHelper();

const axiosClient = axios.create({
  headers: {
    Accept: 'application/json',
  },
  responseType: 'json',
  baseURL: `${getFrontendConfig('billingApi')}`,
});

export function getProductsById() {
  return async (dispatch) => {
    dispatch(productsRequest());
    try {
      const response = await axiosClient
        .get('/cache/products_id.json');
      if (response.status > 200) throw new Error('Error getting products');
      const data = _.forEach(response.data, (product) => {
        _.set(product, 'productName', _.get(productNames, `${product.id}`, product.id));
        _.set(product, 'productType', _.get(productTypes, `${product.id}`, product.productType));
        _.set(product, 'productDescription', _.get(productDescriptions, `${product.id}`, product.productDescriptions));
        _.set(product, 'productFeatures', _.get(productFeatures, `${product.id}`, ''));
        _.set(product, 'productBillingPlan', _.get(productBillingPlans, `${product.id}`, ''));
        _.set(product, 'productTotalLine', _.get(productTotalLine, `${product.id}`, ''));
        _.set(product, 'sort', _.get(productSortOrder, `${product.id}`, 99));
        _.set(product, 'sort2', _.get(productSortOrderMobile, `${product.id}`, 99));
        _.set(product, 'logo', getProductLogo(product.id));
        if (!_.get(product, 'price', false)) _.set(product, 'price', 'contact for price');
      });
      dispatch(productsSuccess(data));
    } catch (err) {
      dispatch(productsFailure(err));
    }
  };
}
export default getProductsById;
