import _ from 'lodash';
import { combineReducers } from 'redux';
import { v4 as uuid } from 'uuid';
import { getFrontendConfig } from '../../utils/clientHelper';

import { ACTION_TYPES } from './actions';

const emptyCreateSubscriptionModel = () => ({
  object: 'Subscription',
  id: uuid().replace(/-/g, ''),
  account: {
    object: 'Account',
    id: '',
    email: '',
    email_type: 'html',
  },
  billing_plan: { object: 'BillingPlan' },
  payment_method: {
    object: 'PaymentMethod',
    vid: '',
  },
  currency: getFrontendConfig('vindicia.currency'),
  description: '',
  starts: '',
  items: [],
  campaignCode: '',
  discountTotal: 0,
  nextTotal: 0,
  taxesTotal: 0,
  billingTotal: 0,
});

const initialUpdateSubscriptionModel = () => ({
  object: 'Subscription',
  id: null,
  billing_plan: { object: 'BillingPlan' },
  items: [],
});

const subscriptionItemTemplate = () => ({
  object: 'SubscriptionItem',
  id: uuid().replace(/-/g, ''),
  product: {
    object: 'Product',
  },
});

const removeSubscriptionItemTemplate = () => ({
  object: 'SubscriptionItem',
  replaces: null,
});

function updateSubscriptionReducer(state = initialUpdateSubscriptionModel(), { type, payload }) {
  const newState = { ...state };

  const findAddIndex = (item) => {
    const check1 = payload.subscriptionItem && item.replaces === payload.subscriptionItem.id;
    const check2 = item.product && item.product.id === payload.selectedProductId;

    return check1 || check2;
  };

  switch (type) {
    case ACTION_TYPES.SUBSCRIPTIONS_UPDATE_ADD_ENHANCEMENT_PRODUCT: {
      const existsInCurrentSubscriptionIndex = newState.items ? newState.items.findIndex(findAddIndex) : -1;

      if (existsInCurrentSubscriptionIndex > -1) newState.items.splice(existsInCurrentSubscriptionIndex, 1);
      else {
        const subItem = subscriptionItemTemplate();

        subItem.product.id = payload.selectedProductId;
        newState.items.push(subItem);
      }

      return newState;
    }
    case ACTION_TYPES.SUBSCRIPTIONS_UPDATE_REMOVE_ENHANCEMENT_PRODUCT: {
      const newItemIndex = newState.items.indexOf((item) => item.product.id === payload.selectedProductId);

      if (newItemIndex > -1) newState.items.splice(newItemIndex, 1);
      else {
        const subItem = removeSubscriptionItemTemplate();

        subItem.replaces = payload.subscriptionItem.id;
        newState.items.push(subItem);
      }

      return newState;
    }
    case ACTION_TYPES.SUBSCRIPTIONS_UPDATE_ADD_BASE_PRODUCT: {
      if (payload.selectedProductId !== payload.currentBaseSubscriptionItem.product.id) {
        const subItem = subscriptionItemTemplate();

        subItem.product.id = payload.selectedProductId;
        subItem.replaces = payload.currentBaseSubscriptionItem.id;
        newState.items.push(subItem);
      } else newState.items = [];

      return newState;
    }
    case ACTION_TYPES.SUBSCRIPTIONS_CANCEL_AUTO_BILL_SUCCESS:
    case ACTION_TYPES.SUBSCRIPTIONS_REQUEST: {
      return initialUpdateSubscriptionModel();
    }
    default:
      return state;
  }
}

const initialState = () => ({
  total_count: 0,
  data: [],
  url: '',
  next: '',
  previous: '',
  object: 'List',
});

function subscriptionReducer(state = initialState(), { type, payload }) {
  switch (type) {
    case ACTION_TYPES.SUBSCRIPTIONS_SUCCESS: {
      return { ...state, ...payload };
    }
    case ACTION_TYPES.SUBSCRIPTIONS_UPDATE_AUTO_BILL:
      return { ...state, ...payload };
    case ACTION_TYPES.SUBSCRIPTIONS_CREATE_AUTO_BILL: {
      const newState = { ...initialState() };

      newState.data.push(payload);
      newState.total_count = newState.data.length;

      const baseUrl = `/subscriptions?account=${encodeURI(payload.account.id)}`;

      newState.url = baseUrl;
      newState.next = `baseUrl&starting_after=${encodeURI(payload.id)}`;
      newState.previous = `baseURL&ending_before=${encodeURI(payload.id)}`;

      return newState;
    }
    case ACTION_TYPES.SUBSCRIPTIONS_REQUEST:
      return initialState();
    default:
      return state;
  }
}

function createSubscriptionReducer(state = emptyCreateSubscriptionModel(), { type, payload }) {
  let newState = { ...state };

  switch (type) {
    case ACTION_TYPES.SUBSCRIPTIONS_CREATE_ADD_PAYMENT_METHOD: {
      newState.payment_method = payload;

      return newState;
    }
    case ACTION_TYPES.SUBSCRIPTIONS_CREATE_ADD_CAMPAIGN_CODE: {
      newState.campaignCode = payload.campaignCode;
      newState.discountTotal = payload.discountTotal;
      newState.nextTotal = payload.nextTotal;

      return newState;
    }

    case ACTION_TYPES.SUBSCRIPTIONS_CREATE_REMOVE_CAMPAIGN_CODE: {
      newState.campaignCode = '';
      newState.discountTotal = 0;
      newState.nextTotal = 0;

      return newState;
    }
    case ACTION_TYPES.SUBSCRIPTIONS_CREATE_ADD_BASE_PRODUCT: {
      if (newState.items.length === 0) {
        newState = Object.assign(
          newState,
          {
            account: {
              object: 'Account',
              id: payload.accountId,
              email: payload.email,
              email_type: 'html',
            }
          },
          {
            items: [],
          }
        );
      }
      newState.items = _.filter(newState.items, { productType: 'ENHANCEMENT' });

      const subItem = subscriptionItemTemplate();
      subItem.product = payload.selectedProduct;
      newState.items.push(subItem);

      return newState;
    }

    case ACTION_TYPES.SUBSCRIPTIONS_CREATE_ADD_ENHANCEMENT_PRODUCT: {
      const subItem = subscriptionItemTemplate();

      subItem.product.id = payload.selectedProductId;
      subItem.product.productType = 'ENHANCEMENT';
      newState.items.push(subItem);

      return newState;
    }

    case ACTION_TYPES.SUBSCRIPTIONS_CREATE_REMOVE_ENHANCEMENT_PRODUCT: {
      if (newState.items.length > 0) {
        newState.items = _.remove(newState.items, (item) => payload.selectedProductId !== item.product.id && item.productType !== 'ENHANCEMENT');
      }

      return newState;
    }

    case ACTION_TYPES.SUBSCRIPTIONS_CANCEL_AUTO_BILL_SUCCESS:
    case ACTION_TYPES.SUBSCRIPTIONS_REQUEST: {
      return emptyCreateSubscriptionModel();
    }
    default:
      return state;
  }
}

export default combineReducers({
  subscriptionModel: subscriptionReducer,
  createSubscriptionModel: createSubscriptionReducer,
  updateSubscriptionModel: updateSubscriptionReducer,
});
