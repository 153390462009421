import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { withRouter } from 'react-router';
import Callback from './containers/Callback';
import CoreLayout from './layouts/CoreLayout';
import MarketingComponent from './components/MarketingComponent';
import SubscriptionContainer from './containers/SubscriptionContainer';
import PaymentContainer from './containers/PaymentContainer';
import { UserContext } from './contexts/userContext';
import { getFrontendConfig } from './utils/clientHelper';

const enabledMarketing = getFrontendConfig('MarketingComponent.enabled');
const enabledSubscription = getFrontendConfig('SubscriptionContainer.enabled');

class Routes extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.landingPath = this.landingPath.bind(this);
  }

  handleAuthentication = (nextState, replace) => {
    if (/access_token|id_token|error/.test(nextState.location.hash)) this.context.authService.handleAuthentication();
  };

  landingPath = (props) => {
    if (this.context.authService.isAuthenticated()) {
      if (enabledSubscription) {
        return (
          <CoreLayout>
            <SubscriptionContainer {...props} />
          </CoreLayout>
        );
      }
    }
    if (enabledMarketing) {
      return (
        <CoreLayout>
          <MarketingComponent showHomepageHeading {...props} />
        </CoreLayout>
      );
    }
    if (enabledSubscription) {
      return (
        <CoreLayout>
          <SubscriptionContainer {...props} />
        </CoreLayout>
      );
    }
    // nothing enabled then show marketing
    return <MarketingComponent showHomepageHeading {...props} />;
  };

  render() {
    return (
      <Switch>
        <Route
          exact
          path="/"
          render={(props) => this.landingPath(props)}
        />
        <Route
          path="/callback"
          render={(props) => {
            this.handleAuthentication(props);

            return (
              <CoreLayout disabled={true}>
                <Callback {...props} />
              </CoreLayout>
            );
          }}
        />
        <CoreLayout>
          {enabledSubscription && this.context.authService.isAuthenticated() ? (
            <>
              <Route exact path="/subscription" component={SubscriptionContainer} />
              <Route path="/subscription/products" component={SubscriptionContainer} />
              <Route path="/subscription/payment" component={PaymentContainer} />
            </>
          ) : null}
        </CoreLayout>
      </Switch>
    );
  }
}

export default withRouter(Routes);
