import { createBrowserHistory } from 'history';

class BrowserHistory {
  constructor() {
    if (!BrowserHistory.instance) {
      this._singletonHistory = createBrowserHistory();
      BrowserHistory.instance = this;
    }

    return BrowserHistory.instance;
  }

  get() {
    return this._singletonHistory;
  }
}

const instance = new BrowserHistory();

Object.freeze(instance);

export default instance.get();
