/* eslint-disable max-len */
export const terms = `Terms and Conditions
Welcome! This is a sample Terms and Conditions.  It should not be taken as-is.  This is simply a representation for subscription type products.
The following terms govern your use of this website (“Site”), applications and related services (collectively, the “Service”). “You” and “Your” refer to the person or entity that accesses or uses the Services as the end user, and “Rebar,” "we" or "us" refers to rebartechnology.com and its owner, and corporate affiliates.
BY SIGNING UP OR ACCESSING OR USING THE SERVICE, YOU HEREBY AGREE TO THESE TERMS ("AGREEMENT" OR "TERMS") AND TO RESOLVE DISPUTES BY ARBITRATION.
1. Summary of Terms
These Terms have the same force and effect as an agreement signed in writing.
If you do not agree to these Terms, you may not use the Service.
Each time you access the Service or any Content (defined below) of the Service, you signify your agreement to the then-current Terms.
This Agreement is subject to change at any time; therefore, you are responsible to review these Terms regularly to learn about any changes. We will post an updated version of these Terms if we modify them. You understand and agree that your ongoing use of the Service after we post or provide notice of the changes to this Agreement means that you accept the updated Terms and agree to abide by them.
The laws of the State of Illinois govern this Agreement.
You agree to resolve any dispute with us as provided in the Dispute Resolution Procedures described in Section 17 below, which includes mandatory arbitration, subject to certain limited exceptions, and to waive the right to participate in class actions.
Definitions
"Login" means the combination of unique username and password that is used to access our Service.
"Membership Account" means a single registration and/or subscription account usable by one user (a “Member”) for the applicable subscription term associated with a valid Login.
2. Account Registration
In order to sign up for a membership, you must:
Provide true, complete and up-to-date registration information about yourself;
Agree to abide by these Terms;
Establish a Login to access certain features of the Service; and
Be of legal age and competence to enter into contracts.
We reserve the right to refuse service or to any user and to change eligibility requirements at any time. We may refuse the use of any Login that we determine is unacceptable. Only one user is permitted per Login and Membership Account.
3. Authorization to Use; Termination; Your Responsibilities
Authorization to Use
Subject to your compliance with the terms and conditions of this Agreement, and provided that you have a Membership Account, we grant you a limited, revocable, non-transferable, non-sublicenseable, right and license to access Service and the Content for personal, non-commercial use.
You are not authorized to transfer Content from the Service to any other person or entity, whether commercial or non-commercial.
Termination
At any time, and with or without cause, we may cancel or terminate and not renew your access to the Service or your Membership Account in our sole discretion. If termination is for other than cause, your termination will not be effective until you have used services for which you have paid. If you breach this Agreement, we may, in our reasonable discretion, terminate your access to the site with immediate effect and without compensation to you.
Your responsibilities
You are responsible for the following:
To keep secure your Login and all devices that you use to access the Service; and
For all conduct on the Service that is associated with your Membership Account, including activities that occur under your Membership Account (whether others can access your account through your Login or any of your devices, including when the Login or device is lost or stolen).
You are required to keep your Login strictly confidential. You may not share your Login credentials with anyone else, or allow other users to access the Service through your Login or Membership Account. You agree to notify us immediately if any breach of security, theft or loss of login, or unauthorized access to your Membership Account or disclosure of your Login information occurs. You will remain liable for unauthorized use of the Service until we are notified of the security breach by email or telephone.
4. Ownership rights

All content on the Site, or otherwise made available via the Site, including without limitation, the text, notes, graphics, photos, sounds, music, videos, interactivities and the like (“Content”), the trademarks, service marks and logos contained therein (“Marks”), the design of the Site and/or Services (“Site Design”), and all software and other technology used to provide the Site and/or Services (“Technology”), are owned by or licensed to Rebar and/or its affiliates. Content is provided to you AS IS for your information and personal use only and may not be used, copied, reproduced, distributed, transmitted, broadcast, displayed, sold, licensed, or otherwise exploited for any other purposes whatsoever. We reserve all rights not expressly granted in and to the Site, Content, Marks, Site Design and Technology. Using the Site and/or Services does not give you any ownership of or right in or to any Content, Marks, Site Design or Technology.
5. We are not obligated for content provided by others
You understand and agree that the Service may contain Content which is licensed to us or posted to the Site by a third party, including other end users of the Service, such as Submissions (defined below). We are not responsible or liable for any Content that are prepared by, posted, or provided by others, including Submissions, or any content placed by third-party advertisers.
We are not responsible for content on the Internet, including any websites or content linked from the Service.
We have no duty to pre-screen Content, although we may do so in our sole discretion and without liability to you. We do not condone, encourage, or knowingly take part in plagiarism, unauthorized copying or redistribution of content or any other acts of academic fraud or dishonesty. We reserve the right to remove Content from the Service for any reason, but we are not responsible for any failure or delay in removing such Content.
6. Usage Rules and Prohibited Uses

You agree to comply with all applicable laws and these Terms when using the Services. You are responsible for maintaining, at your own expense, the equipment and Internet access that you will need to use the Service. You must back up at your own expense any data, such as contact information, files, and other data that you desire to keep (we will not back up this data on your behalf).
You may use the Service and the Content to conduct research and as example documents for research use. You must properly cite and attribute any information that you may use from the Service.
You may not, and may not permit a third party to, submit, offer links from, share, post, or transmit through the Service any material, or otherwise engage in any conduct, that:
Is false, fraudulent, or misleading (including any negligent misrepresentations);
Violates or infringes the rights of others, including, without limitation, patent, trademark, trade secret, copyright, publicity, privacy or other proprietary rights;
Offers for sale any content or products;
Is unlawful, threatening, abusive, harassing, defamatory, libelous, deceptive, obscene, pornographic, invasive of another's privacy, tortious, or contains explicit or graphic descriptions, or accounts of, sexual acts;
Victimizes, harasses, degrades, or intimidates an individual or group of individuals on the basis of religion, gender, sexual orientation, race, ethnicity, age, disability, or other category protected by law;
Impersonates any person, business or entity, including our employees and agents;
Contains viruses, Trojan horses, works, time bombs or any other computer code, files, or programs that interrupt, destroy, or limit the functionality of any computer software or hardware or telecommunications equipment, or otherwise permit the unauthorized use of a computer or computer network;
Includes or constitutes spam or other forms of unsolicited communications;
Encourages conduct that would constitute a criminal offense, or that gives rise to civil liability;
Offers, promotes, or encourages betting or wagering prohibited by law;
Violates this Agreement, guidelines, the anti-spam terms below, or any policy posted on the Service;
Interferes with the use of the Service by others;
Attempts to frame or redistribute the Service;
Inserts your own or a third party's advertising, branding, or other promotional content into any the Service;
Engages in the practices of "screen scraping", "database scraping," or any other activity with the purpose of obtaining and/or copying our database, our lists of users, or other information, including without limitation harvesting information about our users for the purpose of sending, or to facilitate the sending of, unsolicited bulk communications;
Offers content that is commercial in nature, (i.e., advertisements);
Offers content that merely links to third party sites; or
Modifies, translates, decompiles, creates derivative work(s) of, copies, distributes, disassembles, broadcasts, transmits, publishes, alters or removes any legends, notices (including proprietary notices) or markers from, licenses, sublicenses, transfers, sells, mirrors, frames, exploits, rents, leases, private labels, grants a security interest in, or otherwise uses the Content and Services in any manner not expressly permitted in these Terms.
We reserve the right to take any legal action or and technical remedies to prevent the violation of this Agreement by any user.
8. Privacy Policy
You hereby acknowledge that you have read and understood our Privacy Policy, which details our privacy practices.
9. Electronic Messaging
Notifications
By registering for the Service, you are allowing us to send emails regarding your account. Such emails may include, but are not limited to, those related to an action you took directly, an action of which you were a target, or an inaction on your part.
Marketing Emails
By registering for the Service, you are allowing us to send you emails or other electronic messages containing news, updates, and promotions relating to products. You can unsubscribe at any time via the unsubscribe links found on every email or via your account settings page.
Without limiting the generality of the foregoing, you agree that we may contact you using any contact information you have provided to us, including but not limited to contact via email, text (SMS, or "Short Message Service"), mobile/cellular telephone number, home telephone number and/or regular mail. Our text messages to your mobile/cellular telephone numbers could result in charges to you for which you are solely responsible.
10. Compliance with Laws

Recognizing the global nature of the Internet, you agree to comply with all local rules regarding online conduct. You must comply with all local laws within the territories that you access the Service.
11. DISCLAIMER OF WARRANTIES

We provide the Service "as-is," "with all faults" and "as available." We do not guarantee the accuracy or timeliness of the Service. WE GIVE NO EXPRESS WARRANTIES, GUARANTEES OR CONDITIONS. WE DISCLAIM ANY IMPLIED WARRANTIES, INCLUDING THOSE OF MERCHANTABILITY, ACCURACY, FITNESS FOR A PARTICULAR PURPOSE, WORKMANLIKE EFFORT AND NON-INFRINGEMENT. WE MAKE NO REPRESENTATIONS OR GUARANTEES THAT THE SERVICE AND ITS CONTENT AND MATERIALS WILL MEET YOUR REQUIREMENTS. YOU USE THE SERVICE AND ITS CONTENT ENTIRELY AT YOUR OWN RISK.
WE DO NOT REPRESENT OR WARRANT THAT THE FUNCTIONS CONTAINED ON THE SITE WILL BE UNINTERRUPTED OR ERROR-FREE, SECURE, THAT DEFECTS WILL BE CORRECTED, THAT THE SITE OR SERVICES WILL WORK IN THE CONFIGURATION OR WITH HARDWARE OR SOFTWARE YOU USE, OR THAT THE SITE OR THE SERVERS THAT MAKE THE SITE AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. THE SITE, CONTENT, AND PRODUCTS MAY CONTAIN TECHNICAL INACCURACIES OR TYPOGRAPHICAL ERRORS OR OMISSIONS. REBAR ASSUMES NO RESPONSIBILITY FOR AND DISCLAIMS ALL LIABILITY TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW FOR ANY SUCH INACCURACIES, ERRORS OR OMISSIONS.
YOU AGREE THAT YOUR USE OF THE SITE SHALL BE AT YOUR SOLE RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGES TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF MATERIAL OR DATA.
TO RESIDENTS OF NEW JERSEY: THE PROVISIONS ABOVE ARE INTENDED TO BE AS BROAD AND INCLUSIVE AS IS PERMITTED BY THE LAW OF THE STATE OF NEW JERSEY ONLY.
12. LIABILITY LIMITATION
YOUR SOLE AND EXCLUSIVE REMEDY FOR ANY DISPUTE WITH US OR YOUR USE OF THE SERVICE IS TO DISCONTINUE YOUR USE OF THE SERVICE. WE, OUR OWNERS, EMPLOYEES, REPRESENTATIVES AND AGENTS, SHALL NOT BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES ARISING FROM YOUR USE OF, INABILITY TO USE, OR RELIANCE UPON YOUR USE OF THE SERVICE, EVEN IF WE KNEW OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES. IN ANY EVENT, OUR AGGREGATE LIABILITY SHALL NOT EXCEED THE AMOUNT PAID BY YOU TO REBAR OVER THE TWELVE (12) MONTHS PRECEDING THE CLAIM. THIS PROVISION IS NOT INTENDED TO EXCLUDE LIABILITY THAT WE MAY NOT EXCLUDE UNDER APPLICABLE LAW.
TO RESIDENTS OF NEW JERSEY: THE PROVISIONS ABOVE ARE INTENDED TO BE AS BROAD AND INCLUSIVE AS IS PERMITTED BY THE LAW OF THE STATE OF NEW JERSEY ONLY.
13. Release, Indemnity
By availing yourself of your use of the Service, you agree to indemnify and release and hold us and our employees, officers, directors, shareholders, agents, representatives, affiliates, subsidiaries, advertising, promotion and fulfillment agencies, any third-party providers or sources of information or data and legal advisers harmless from any and all losses, damages, rights, claims, and actions of any kind arising from or related to your use or misuse of the Service.
14. Electronic Agreement, Notice

We transact with our users electronically and, therefore, we may provide you with required notices and terms electronically, either by sending you an e-mail to the address that you have provided to us, or by posting a notice on the appropriate Web page. By using the Service or registering with us, you represent that you have the necessary equipment, software and internet access to read, review, print and store any terms or notices that we provide to you. Your affirmative act of either (i) accessing and using the Service and/or (ii) registering with us, and/or (iii) providing a Submission, constitutes your electronic signature to this Agreement. You acknowledge that these Terms may not be denied legal effect or enforceability solely because this Agreement was formed electronically.
15. General Terms

These terms make up the entire agreement between you and us regarding your use of the Service.
We may assign this Agreement, in whole or in part, at any time, with or without notice to you.
You may not assign this Agreement, or any part of it, to any other person.
If any provision of this Agreement shall be held to be invalid or unenforceable for any reason, the remaining provisions shall continue to be valid and enforceable. If a court finds that any of this Agreement is invalid or unenforceable, but that by limiting such provision it would become valid or enforceable, then such provision shall be deemed to be written, construed, and enforced as so limited.
16.  Intentionally Omitted
17. Binding Arbitration and Class Action Waiver
This Binding Arbitration and Class Action Waiver (this “Waiver”) applies to any dispute arising between you and Rebar regarding these Terms. “Dispute”, for purposes of this Waiver, means any dispute, action, or other controversy whether in contract, warranty, tort, statute, regulation, ordinance, or any other legal or equitable basis. “Dispute” will be given the broadest possible meaning allowable under law. The one exception to the exclusivity of arbitration is that you have the right to bring an individual claim against Rebar in a small-claims court of competent jurisdiction. Notwithstanding the foregoing, Rebar and you both further agree that either party may bring suit in court solely to enjoin infringement or other misuse of intellectual property rights.
In the event of a Dispute, you must provide Rebar with a Notice of Dispute, which is a written statement of the name, address and contact information of the party giving it, the facts giving rise to the Dispute, and the relief requested. 
If you or Rebar do not resolve any Dispute by informal negotiation, any other effort to resolve the Dispute shall be by BINDING ARBITRATION administered by the American Arbitration Association under the Consumer Arbitration Rules. The one exception to the exclusivity of arbitration is that you have the right to bring an individual claim against the Rebar in a small-claims court of competent jurisdiction. Whether you choose arbitration or small-claims court, you may not under any circumstances commence or maintain against the Rebar any class action, class arbitration, or other representative action or proceeding. The proceeding will be conducted exclusively by binding arbitration governed by the United States Federal Arbitration Act ("FAA"). Notwithstanding the foregoing, Rebar and you both further agree that either party may bring suit in court solely to enjoin infringement or other misuse of intellectual property rights.
By using the Services in any manner, you agree to the above arbitration agreement. In doing so, YOU GIVE UP YOUR RIGHT TO GO TO COURT to assert or defend any claims between you and Rebar (except for matters that may be taken to small-claims court). YOU ALSO GIVE UP YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION OR OTHER CLASS PROCEEDING. Your rights will be determined by a NEUTRAL ARBITRATOR, NOT A JUDGE OR JURY, and the arbitrator shall determine all issues regarding the arbitrability of the dispute. You are entitled to a fair hearing before the arbitrator. The arbitrator can grant any relief that a court can, but you should note that arbitration proceedings are usually simpler and more streamlined than trials and other judicial proceedings. Decisions by the arbitrator are enforceable in court and may be overturned by a court only for very limited reasons. For details on the arbitration process, see our Arbitration Procedures.
To the extent permitted by law, any Dispute must be filed within one year. The one-year period begins when the Dispute first could be filed. If such a Dispute is not filed within one year, it is permanently barred.
If this Waiver is found to be illegal, invalid or unenforceable as to all or some parts of a Dispute, then this Waiver will not apply to those parts. Instead, those parts will be severed and proceed in a court of law, with the remaining parts proceeding in arbitration. If any other provision of this Waiver is found to be illegal, invalid or unenforceable, that provision will be severed with the remainder of this Waiver remaining in full force and effect.
18. Questions and Contact Information

All questions regarding these terms and conditions must be directed to:


By mail:

By email:
info@rebar.com

19. Billing and Refunds

This section applies for any purchases or fee-based memberships and services that you may have with us.
Billing
You agree to pay all fees and charges that you incur subject to the payment terms that will be disclosed to you at the time you make your purchase. When you make a purchase from us, you must provide us with a payment method. You must be authorized to use the payment method. You authorize us to charge you for the Service using your payment method. You will pay service charges in advance. You are liable for charges incurred by you until termination of Service. You are solely responsible for any charges imposed by your credit card issuing bank for exceeding your account limits or overdrawing your account.
Automatic Recurring Billing
As determined by the content provider of the Service, subscription fees may be automatically renewed at the end of the original term selected, for a similar period of time, unless notice is received from the subscriber seven (7) days before renewal. Unless and until this Agreement is cancelled in accordance with the terms hereof, you hereby authorize us to charge your chosen payment method to pay for the ongoing cost of membership. You hereby further authorize us to charge your chosen payment method for any and all additional purchases of services and entertainment provided by the Service.
Electronic Receipt
You will receive an email receipt to the email address you provided upon initial subscription of your membership. You may, at any time, request a copy of the account of charges made for the life of their membership to the Service. You agree that you forego this right if such request is not made. Requests must be made directly to us. To contact us, refer to the Questions and Contact Information detailed in Section 23.
Refunds
Subscription fees are NOT refundable if you request to cancel or terminate your membership. Should a refund be issued by us, all refunds will be credited solely to the payment method used in the original transaction. We will not issue refunds by cash, check, or to another credit card or payment mechanism.
Arbitration Procedures
Overview. Arbitration is an alternative to litigation where a neutral person (the arbitrator) hears and decides the parties’ dispute. Arbitration proceedings are designed to provide parties with a fair hearing in a manner that is faster and less formal than court proceedings. The following procedures (the “Arbitration Procedures”) are applicable to all arbitration proceedings involving you and Rebar.
Pre-Arbitration Dispute Resolution. Rebar is always interested in resolving disputes amicably and efficiently. So before you commence an arbitration, we suggest that you contact us to explain your complaint, as we may be able to resolve it without the need for arbitration. You may contact us online.
Administrator. The administrator for the arbitration is the American Arbitration Association (“AAA”), a non-profit organization that is not affiliated with Rebar. The AAA facilitates, but does not itself conduct, the arbitration. The arbitrator who will hear and decide your dispute will be chosen from the AAA’s roster of neutral arbitrators. For information on the AAA, please visit its website, https://www.adr.org. Information about the AAA’s rules and fees for consumer disputes can be found at the AAA’s consumer arbitration page,https://www.adr.org/Arbitration.
Applicable Rules. The arbitration will be governed by the AAA’s Consumer Arbitration Rules (the “AAA Rules”), as modified by these Arbitration Procedures. If there is any inconsistency between the AAA Rules and these Arbitration Procedures, the Arbitration Procedures will control. However, if the arbitrator determines that strict application of the Arbitration Procedures would not result in a fundamentally fair arbitration, the arbitrator may make any order necessary to provide a fundamentally fair arbitration that is consistent with the AAA Rules.
Commencing an Arbitration. To commence an arbitration against Rebar, you must complete a short form, submit it to the AAA, and send a copy to Rebar.   To learn more about commencing an arbitration and to obtain a form to institute arbitration, see the AAA’s claim filing page,https://www.adr.org/FileOnline, and its guide on Representing Yourself in Arbitration,https://info.adr.org/prose/. You may represent yourself in the arbitration or have a lawyer (or some other representative) act on your behalf. Upon receipt of an arbitration claim, Rebar may assert any counterclaims it may have against the complaining party.
Fees. You are responsible for paying your portion of the fees set forth in the AAA’s fee schedule for consumer disputes. Rebar will pay all remaining fees. If your claim against Rebar is for less than $1,000, we will pay all fees. If you believe you cannot afford the AAA’s fee, you may apply to the AAA for a fee waiver.
Selection of the Arbitrator. The parties, using the AAA’s standard procedures, will select a single arbitrator from a roster of neutrals prepared by the AAA.
Discovery. Each party may (a) request relevant, non-privileged documents from the other party; and (b) request that the other party provide the particulars of its claims or defenses. Any such discovery requests must be served on the other party within 10 days after the arbitrator’s appointment. The responding party shall provide the requesting party with all responsive, non-privileged documents, the requested particulars, and/or any objections to the requests within 15 days after receipt of the requests. Any disputes about discovery or requests for extensions shall be submitted promptly to the arbitrator for prompt resolution. In ruling on any discovery dispute or extension request, the arbitrator shall take into consideration the nature, amount, and scope of the underlying arbitration claim, the cost and other effort what would be involved in providing the requested discovery, the case schedule, and whether the requested discovery is necessary for the adequate preparation of a claim or defense.
Communications with the Arbitrator. Whenever communicating with the arbitrator, the parties must include each other – for example, by including the other party on a telephone conference call and copying the other party on any written submissions, such as letters or emails. To the extent practicable, conferences with the arbitrator will take place by telephone conference call or email. Ex parte communications are not permitted with any arbitrator.
Confidentiality. Upon either party’s request, the arbitrator will issue an order requiring that confidential information of either party disclosed during the arbitration (whether in documents or orally) may not be used or disclosed except in connection with the arbitration or a proceeding to enforce the arbitration award and that any permitted filing of confidential information must be done under seal.
Arbitration Award. The arbitrator will render a written decision within 14 days after the hearing or, if no hearing was held, within 30 days after any rebuttal or supplemental statements are due. The decision must clearly specify the relief, if any, awarded and contain a brief statement of the reasons for the award.
`;

export default terms;
