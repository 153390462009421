import { ACTION_TYPES } from './actions';

const initialState = {};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case ACTION_TYPES.PRODUCTS_SUCCESS:
      return { ...state, ...payload.results };

    case ACTION_TYPES.PRODUCTS_FAILURE:
      return initialState;

    case ACTION_TYPES.PRODUCTS_REQUEST:
    default:
      return state;
  }
}
