/* eslint-disable class-methods-use-this */
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  Segment,
  Header,
} from 'semantic-ui-react';
import VindiciaFormWrapper from '../components/VindiciaFormWrapper';
import { getFrontendConfig } from '../utils/clientHelper';
import getClientIP from '../utils/getClientIP';
import { openModal, closeModal } from '../redux/modals/actions';
import SimpleLoader from '../components/SimpleLoader';
import SubscriptionDisplay from '../components/SubscriptionDisplay';
import TermsAndConditions from '../components/TermsAndConditions';
import ShippingAddress from '../components/ShippingAddress';
import { createSubscription } from '../redux/subscriptions/thunks';
import history from '../history';

const intialState = {
  failureMessage: false,
  enableContainer: false,
  submitted: false,
  sourceIp: null,
  loaderActive: true,
  loaderMessage: 'Loading Payment Fields',
  agreedToTermsAndConditions: undefined,
  showingTermsAndConditions: false,
  shippingAddress: {
    sameAddress: true,
    shippingAddressLine1: '',
    shippingAddressCity: '',
    shippingAddressDistrict: '',
    shippingAddressPostalCode: '',
  },
};

const vindiciaOptions = {
  formId: 'mainForm',
  vindiciaAuthId: getFrontendConfig('vindicia.authId'),
  vindiciaServer: getFrontendConfig('vindicia.server'),
  vindiciaRestServer: getFrontendConfig('vindicia.restServer'),
  hmac: getFrontendConfig('vindicia.hmac'),
};

class PaymentContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...intialState };

    // state being sent via router props
    if (props.location.state) Object.assign(this.state, props.location.state);
    this.isHOALoaded = this.isHOALoaded.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onFormSubmitComplete = this.onFormSubmitComplete.bind(this);
    this.onFormSubmitCompleteFailed = this.onFormSubmitCompleteFailed.bind(this);
    // this.onFieldEvent = this.onFieldEvent.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckBox = this.handleCheckBox.bind(this);
    this.handleAgree = this.handleAgree.bind(this);
    this.onFormCancel = this.onFormCancel.bind(this);
  }

  isHOALoaded() {
    const { sourceIp } = this.state;
    if (window.vindicia && window.vindicia.isLoaded() && sourceIp) {
      clearInterval(this.intervalID);
      this.setState({ enableContainer: true, loaderActive: false });
    }
  }

  componentDidMount() {
    const { sourceIp } = this.state;
    if (!sourceIp) {
      getClientIP().then((ip) => {
        this.setState({ sourceIp: ip });
      });
    }

    if (window.vindicia) {
      this.setState({ vindicia: window.vindicia });
    } else {
      document.querySelector('#vindicia-js').addEventListener('load', () => {
        this.setState({ vindicia: window.vindicia });
      });
    }
    this.intervalID = setInterval(this.isHOALoaded, 500);
    return true;
  }

  handleChange(e, data) {
    const { name, value } = data;
    this.setState({ shippingAddress: { [name]: value } });
  }

  handleCheckBox(e, data) {
    const { name, checked } = data;
    this.setState({ shippingAddress: { [name]: checked } });
  }

  onFormSubmitComplete = (data) => {
    const paymentVid = _.get(data, 'detail.vid', false);
    const {
      createSubscriptionModel,
      createSubscriptionProps,
      closeModalProps,
    } = this.props;

    closeModalProps();
    if (paymentVid) {
      history.replace('/subscription');
      this.setState({ submitted: true, loaderActive: true, loaderMessage: 'Creating Subscription' });

      const shippingAddress = {
        sameAddress: _.get(this, 'state.shippingAddress.sameAddress', true),
        shippingAddressLine1: _.get(this, 'state.shippingAddress.shippingAddressLine1', ''),
        shippingAddressCity: _.get(this, 'state.shippingAddress.shippingAddressCity', ''),
        shippingAddressDistrict: _.get(this, 'state.shippingAddress.shippingAddressDistrict', ''),
        shippingAddressPostalCode: _.get(this, 'state.shippingAddress.shippingAddressPostalCode', ''),
      };
      _.set(createSubscriptionModel, 'payment_method.vid', paymentVid);
      _.set(createSubscriptionModel, 'account.shippingAddress', shippingAddress);

      createSubscriptionProps(createSubscriptionModel)
        .then(() => {
          this.setState({ ...intialState, enableContainer: true, failureMessage: _.get(data, 'detail.status') });
          this.props.openModalProps('PurchaseSuccessModal');
        })
        .catch(() => {
          this.setState({ ...intialState, enableContainer: true, failureMessage: _.get(data, 'detail.status') });
          this.props.openModalProps('PurchaseFailureModal');
        })
        .finally(() => {
          this.setState({ submitted: false, loaderActive: false, loaderMessage: '' });
        });
    } else {
      this.setState({ submitted: false, loaderActive: false, loaderMessage: '' });
    }
  }

  onFormSubmit() {
    const {
      openModalProps,
    } = this.props;
    openModalProps('PurchaseProcessModal');
    return true;
  }

  onFormCancel(e) {
    e.preventDefault();
    history.replace('/subscription');
  }

  onFormSubmitCompleteFailed = (data) => {
    const {
      openModalProps,
      closeModalProps,
    } = this.props;

    closeModalProps();
    this.setState({ ...intialState, enableContainer: true, failureMessage: _.get(data, 'detail.status') });
    openModalProps('PurchaseFailureModal');
    return true;
  }

  handleAgree(e, data) {
    const checked = _.get(data, 'checked');
    if (checked) this.setState({ agreedToTermsAndConditions: checked });
    this.setState({ agreedToTermsAndConditions: undefined });
  }

  render() {
    const {
      enableContainer,
      sourceIp,
      loaderActive,
      loaderMessage,
      agreedToTermsAndConditions,
      showingTermsAndConditions,
      shippingAddress,
    } = this.state;

    const customFields = [
      {
        type: 'name',
        label: 'Account Holder',
        placeholder: 'Name on card',
      },
      {
        type: 'cardNumber',
        label: 'Credit Card Number',
        placeholder: 'Card number',
        autocomplete: 'cc-number',
        formatinput: true,
        maskinput: true,
      },
      {
        type: 'expirationDate',
        label: 'Expiration Date',
        placeholder: 'Expiration date (MM/YY)',
        format: 'MM/YY',
        autocomplete: 'cc-exp',
        formatinput: true,
        maskinput: true,
      },
      {
        type: 'cvn',
        label: 'CVN',
        placeholder: 'CVN',
        autocomplete: 'cc-csc',
      },
      {
        type: 'billing1',
        label: 'Address',
        placeholder: 'Address',
      },
      {
        type: 'city',
        label: 'City',
        placeholder: 'City',
      },
      {
        type: 'district',
        label: 'State',
        placeholder: 'State',
      },
      {
        type: 'postalCode',
        label: 'Postal Code',
        placeholder: 'Postal code',
      },
      {
        type: 'text',
        label: 'Crap Code',
        placeholder: 'Crap code',
      },
      {
        name: 'termsAndConditions',
        render: (
          <TermsAndConditions
            required
            onAgree={this.handleAgree}
            agreedToTermsAndConditions={agreedToTermsAndConditions}
            showingTermsAndConditions={showingTermsAndConditions}
          />
        )
      }
    ];

    const styles = {
      input: {
        width: '200px',
        display: 'block',
        'font-family': '"Helvetica Neue",Helvetica,Arial,sans-serif',
        'font-size': '14px',
        color: '#777',
        height: 'auto',
        padding: '6px 12px',
        margin: '5px 0px 20px 0px',
        'line-height': '1.42857',
        border: '1px solid #ccc',
        'border-radius': '4px',
        'box-shadow': '0px 1px 1px rgba(0,0,0,0.075) inset',
        '-webkit-transition': 'border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s',
        transition: 'border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s',
      },
      select: {
        width: '100%',
        'font-family': "'Helvetica Neue',Helvetica,Arial,sans-serif",
        'font-size': '14px',
        color: '#555',
        height: '34px',
        padding: '6px 12px',
        margin: '5px 0px',
        'line-height': '1.42857',
        border: '1px solid #ccc',
        'border-radius': '4px',
        'box-shadow': '0px 1px 1px rgba(0,0,0,0.075) inset',
        '-webkit-transition': 'border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s',
        transition: 'border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s',
      },
      ':focus': {
        'border-color': '#66afe9',
        outline: '0',
        '-webkit-box-shadow': 'inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6)',
        'box-shadow': 'inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6)',
      },
      '.valid': {
        'border-color': '#228B22',
      },
      '.notValid': {
        'border-color': '#ff0000',
      },
      '.text-block': {
        padding: '10px',
        'background-color': '#ccc',
        width: '300px',
      },
      'button[type="submit"]': {
        margin: '10px',
        padding: '10px 20px',
        'background-color': '#0568ae',
        color: '#fff',
        'border-radius': '4px',
        'font-size': '12px',
        'border-color': '#0568ae',
        '-webkit-box-shadow': 'inset 0, 0',
        'box-shadow': 'inset 0, 0'
      },
      'button[type="submit"][disabled]': {
        'background-color': '#eee',
        color: '#ddd',
        'border-color': '#eee',
        '-webkit-box-shadow': 'inset 0, 0',
        'box-shadow': 'inset 0, 0'
      },
      'button[id="cancelButton"]': {
        margin: '10px',
        padding: '10px 20px',
        'background-color': '#ddd',
        color: '#000',
        'border-radius': '4px',
        'font-size': '12px',
      }
    };

    if ((_.get(this.props, 'createSubscriptionModel.items.0.product.id', '') === '') && !loaderActive) return <Redirect to="/subscription" />;

    if (enableContainer) {
      return (
        <SimpleLoader active={loaderActive} message={loaderMessage}>
          <Segment style={{ maxWidth: '600px' }}>
            <Header as='h3' textAlign='center' >Enter Payment Details</Header>
            <SubscriptionDisplay />
            <Segment raised>
              <VindiciaFormWrapper
                options={vindiciaOptions}
                fields={customFields}
                styles={styles}
                vindicia={window.vindicia}
                onSubmitEvent={this.onFormSubmit}
                onSubmitCompleteEvent={this.onFormSubmitComplete}
                onSubmitCompleteFailedEvent={this.onFormSubmitCompleteFailed}
                // onVindiciaFieldEvent={this.onFieldEvent}
                vinValidate={getFrontendConfig('vindicia.validate')}
                currency={getFrontendConfig('vindicia.currency')}
                ignoreCvnPolicy={getFrontendConfig('vindicia.ignoreCvnPolicy')}
                minChargebackProb={getFrontendConfig('vindicia.minChargebackProb')}
                sourceIp={sourceIp}
                ignoreAvsPolicy={getFrontendConfig('vindicia.ignoreAvsPolicy')}
                onCancel={this.onFormCancel}
              >

              </VindiciaFormWrapper>
              <ShippingAddress
                handleChange={this.handleChange}
                handleCheckBox={this.handleCheckBox}
                shippingAddress={shippingAddress}
              />
            </Segment>
          </Segment>
        </SimpleLoader>
      );
    }
    return <SimpleLoader active={loaderActive} message={loaderMessage} />;
  }
}

function mapStateToProps(store) {
  return {
    createSubscriptionModel: store.subscriptions.createSubscriptionModel,
    currentModal: store.modals,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    openModalProps: (modalType, modalProps) => dispatch(openModal(modalType, modalProps)),
    closeModalProps: (modalType) => dispatch(closeModal(modalType)),
    createSubscriptionProps: (subscriptionObject) => dispatch(createSubscription(subscriptionObject)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentContainer);
