import axios from 'axios';

const axiosClient = axios.create({
  headers: { Accept: 'application/json' },
  responseType: 'json',
});

const getClientIP = () => axiosClient
  .get('https://api.ipify.org?format=json')
  .then((data) => data.data.ip)
  .catch((err) => {
    throw new Error(`There was a problem getting the IP address ${err.message}`);
  });

export default getClientIP;
