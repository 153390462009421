import _ from 'lodash';
import React from 'react';
import {
  Sidebar, Confirm, Segment, Container, Icon, Menu,
} from 'semantic-ui-react';

import { NavLink } from 'react-router-dom';
import { UserContext } from '../contexts/userContext';

import ModalManager from '../containers/modals/ModalManager';

import { getFrontendConfig } from '../utils/clientHelper';

const clientConfig = getFrontendConfig();

class LoginMenuAuth0 extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.handleLogoutClick = this.handleLogoutClick.bind(this);
    this.handleLoginClick = this.handleLoginClick.bind(this);
  }

  handleLoginClick = (event, { trigger: name }) => {
    this.props.handleSidebarHide();
    return this.context.authService.login(name === 'signUpButton');
  };

  handleLogoutClick = () => {
    this.props.handleSidebarHide();
    return this.context.authService.logout();
  };

  render() {
    const showSignUp = (_.get(clientConfig, 'authService.signUp', true)) ? 'ui-button--inline-block' : 'ui-button--hidden';

    return this.context.authService.isAuthenticated() ? (
      <Menu.Item as="a" onClick={this.handleLogoutClick}>
        Log Out
      </Menu.Item>
    ) : (
      <>
        <Menu.Item name="loginButton" as="a" onClick={this.handleLoginClick}>
          Log in
        </Menu.Item >
        <Menu.Item name="signUpButton" as="a" onClick={this.handleLoginClick} className={showSignUp}>
          Sign Up
        </Menu.Item >
      </>
    );
  }
}
class SubscriptionMenuItem extends React.Component {
  static contextType = UserContext;

  render() {
    const { activeMenuItem } = this.props;
    if (this.context.authService && this.context.authService.isAuthenticated() && _.get(clientConfig, 'SubscriptionContainer.enabled')) {
      return (
        <Menu.Item
          name="subscriptionMenuItem"
          active={activeMenuItem === 'subscriptionMenuItem' ? 'true' : 'false'}
          {...(this.context.authService.isAuthenticated() ? {} : { disabled: true })}
        >
          <NavLink
            className="nav-link"
            activeClassName="nav-link--active"
            exact
            to="/subscription"
            onClick={this.props.handleSidebarHide}
          >
            {_.get(clientConfig, 'SubscriptionContainer.label')}
          </NavLink>
        </Menu.Item>
      );
    }
    return null;
  }
}

class MobileLayout extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.state = {
      activeMenuItem: 'homeMenuItem',
      sidebarOpened: false,
      isCancelSubscriptionDialogOpen: false,
    };

    this.handleLoginClick = this.handleLoginClick.bind(this);
    this.handleLogoutClick = this.handleLogoutClick.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const pathname = _.get(props, 'location.pathname', '');

    if (pathname.indexOf('subscription') > 0) return { activeMenuItem: 'subscriptionMenuItem' };

    return { activeMenuItem: 'homeMenuItem' };
  }

  handleLoginClick = (event, { name }) => {
    const { authService } = this.context;
    authService.login(name === 'signUpButton');
  };

  handleLogoutClick = () => this.context.authService.logout();

  getMessageProps = () => ({
    hidden: !this.state.hasApiError,
    visible: this.state.hasApiError,
  });

  onCancelSubscriptionClick = () => {
    this.setState({
      isCancelSubscriptionDialogOpen: true,
      sidebarOpened: false,
    });
  };

  onSubscriptionCancelAbort = () => {
    this.setState({
      isCancelSubscriptionDialogOpen: false,
    });
  };

  onSubscriptionCancelConfirm = () => {
    const { cancelSubscriptionProps, currentSubscription, getSubscriptionsProps } = this.props;
    this.setState({
      enableContainer: false,
      isCancelSubscriptionDialogOpen: false,
    });
    cancelSubscriptionProps(currentSubscription.id).then(() => {
      getSubscriptionsProps().then(() => this.setState({ sidebarOpened: false }));
    });
  };

  handleRef = (c) => {
    this.inputRef = c;
  };

  handleApiUnlock = (event, data) => {
    this.setState({ loading: true });

    return this.context.apiLock
      .unlockApi(this.inputRef.inputRef.value)
      .then((response) => {
        this.setState({ loading: false, hasApiError: false, isModalOpen: false });
      })
      .then(() => this.handleLoginClick())
      .catch((err) => {
        if (err.response.status === 502) {
          // This is the error code returned from the API gateway/Lambda integration
          this.setState({
            loading: false,
            hasApiError: true,
            errorMessage: 'Invalid API key.  Please check your inputs and try again',
          });
        } else {
          this.setState({
            loading: false,
            hasApiError: true,
            errorMessage: err.response.data.message,
          });
        }
      });
  };

  handleSidebarHide = () => this.setState({ sidebarOpened: false });

  handleToggle = () => this.setState({ sidebarOpened: true });

  render() {
    const {
      children, subscriptions, currentSubscription
    } = this.props;
    const { sidebarOpened, activeMenuItem, isCancelSubscriptionDialogOpen } = this.state;

    let showSubscriptionActions = false;
    if (_.get(subscriptions, 'subscriptionModel.total_count', 0) === 0) showSubscriptionActions = false;
    if (_.get(currentSubscription, 'status', 'Cancelled') === 'Cancelled') showSubscriptionActions = false;
    const styleSubscriptionActions = showSubscriptionActions ? { visibility: 'visible' } : { display: 'none' };

    return (
      <Sidebar.Pushable>
        <Sidebar
          as={Menu}
          animation="push"
          onHide={this.handleSidebarHide}
          vertical
          visible={sidebarOpened}
          style={{
            backgroundColor: '#fff', border: 0, margin: 0, padding: 0
          }}
        >
          {/* <Menu.Item
              name="homeMenuItem"
              as="a"
              active='false'
              style={{ marginTop: '55px' }}
              href={_.get(clientConfig, 'homePage.link')}
            >
              Home
            </Menu.Item> */}
          <SubscriptionMenuItem
            activeMenuItem={activeMenuItem}
            styleSubscriptionActions={styleSubscriptionActions}
            handleSidebarHide={this.handleSidebarHide}
          />
          <Menu.Item style={styleSubscriptionActions} />
          <LoginMenuAuth0 handleSidebarHide={this.handleSidebarHide} />
        </Sidebar>

        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Segment textAlign="center" style={{ minHeight: 50, padding: 0 }} vertical>
            <Container style={{ backgroundColor: '#fff', margin: 0 }}>
              <Menu pointing secondary size="large" style={{
                border: 0, margin: 0, padding: 0
              }} >
                <Menu.Item onClick={this.handleToggle}>
                  <Icon name="sidebar" />
                </Menu.Item>
              </Menu>
            </Container>
          </Segment>
          <ModalManager />
          <Segment attached style={{ flex: 1 }}>
            {children}
          </Segment>
        </Sidebar.Pusher>

        <Confirm
          open={isCancelSubscriptionDialogOpen}
          onCancel={this.onSubscriptionCancelAbort}
          onConfirm={this.onSubscriptionCancelConfirm}
          content="Are you sure? This action cannot be undone"
          header="Cancel Subscription"
        />
      </Sidebar.Pushable>
    );
  }
}

export default MobileLayout;
