// https://github.com/markerikson/project-minimek/

import { ACTION_TYPES } from './actions';

const initialState = { modalType: 'PurchaseNoModal' };

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case ACTION_TYPES.MODAL_OPEN:
      return { ...state, ...payload };
    case ACTION_TYPES.MODAL_CLOSE:
      return { ...state, initialState };
    default:
      return state;
  }
}
