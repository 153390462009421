import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import {
  List, Container, Confirm,
} from 'semantic-ui-react';

// import { Link } from 'react-router-dom';
import SimpleLoader from '../components/SimpleLoader';
import ProductContainer from './ProductContainer';
import {
  createSubscriptionAddBaseProduct,
} from '../redux/subscriptions/thunks';
import { getProductsById } from '../redux/products/thunks';
import { makeCurrentSubscriptionState } from '../redux/subscriptions/selectors';

import {
  getFrontendConfig, PropertyNameEnum, productHelper, importAllImages
} from '../utils/clientHelper';

const { productNames, productDescriptions, productLogos } = productHelper();

class SubscriptionContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      enableContainer: false,
      activeProduct: '',
      images: importAllImages(),
      isCancelSubscriptionDialogOpen: false,
      isUpdatingSubscription: false,
    };
    this.handleProductClick = this.handleProductClick.bind(this);
    this.productsContainer = this.productsContainer.bind(this);
    this.disableContainer = this.disableContainer.bind(this);
    this.loaderActive = this.loaderActive.bind(this);
    this.loaderMessage = this.loaderMessage.bind(this);
  }

  componentDidMount() {
    const {
      getProductsByIdProps, products
    } = this.props;

    Promise.all([
      _.isEmpty(products) ? getProductsByIdProps() : null,
    ]).then(() => {
      this.setState({ enableContainer: true });
    });
  }

  handleProductClick(event, data) {
    const {
      updateSubscriptionAddBaseProductProps, createSubscriptionAddBaseProductProps, currentSubscription, products
    } = this.props;
    const { isUpdatingSubscription } = this.state;
    const productId = _.get(data, 'product_id');

    if (isUpdatingSubscription) {
      const baseProduct = currentSubscription.items.data.find((subItem) => products[subItem.product.id].productType === 'BASE');

      updateSubscriptionAddBaseProductProps(productId, baseProduct);
    } else createSubscriptionAddBaseProductProps(products[productId]);

    this.setState({ activeProduct: productId });
  }

  getProductInfo(productType, property, productName) {
    const { currentSubscription, products } = this.props;
    const { images } = this.state;

    const activeProduct = currentSubscription.items.data.find((subItem) => (
      products[subItem.product.id].productType === productType && (productName ? subItem.product.id === productName : true)
    ));
    switch (property) {
      case PropertyNameEnum.LOGO:
        return images[productLogos[activeProduct.product.id].default];
      case PropertyNameEnum.NAME:
        return productNames[activeProduct.product.id];
      case PropertyNameEnum.DESCRIPTION:
        return productDescriptions[activeProduct.product.id];
      default:
        return productNames[activeProduct.product.id];
    }
  }

  getTotalCost() {
    const { currentSubscription, products } = this.props;
    const totalCostCalculator = (accumulator, currentVal) => {
      const ends = _.get(currentVal, 'ends', '2999-12-31T23:00:00');
      return accumulator
        + (new Date(ends.substring(ends.indexOf('T'), -1)) > new Date(Date.now())
          ? parseInt(products[currentVal.product.id].amount, 10)
          : 0
        );
    };
    return currentSubscription.items.data.reduce(totalCostCalculator, 0);
  }

  getEnhancementList() {
    const { currentSubscription, products } = this.props;
    const enhancementProducts = currentSubscription.items.data.flatMap((subItem) => {
      if (products[subItem.product.id].productType !== 'ENHANCEMENT') return [];
      if (!subItem.ends) return [subItem];
      if (Date.parse(subItem.ends.substring(subItem.ends.indexOf('T'), -1)) > new Date(Date.now())) return [subItem];
      return [];
    });

    return _.map(enhancementProducts, (enhancement) => (
      <List.Item key={enhancement.product.id}>
        <List.Icon name="chevron right" />
        <List.Content>
          <List.Header>{this.getProductInfo('ENHANCEMENT', PropertyNameEnum.NAME, enhancement.product.id)}</List.Header>
          <List.Description>
            {this.getProductInfo('ENHANCEMENT', PropertyNameEnum.DESCRIPTION, enhancement.product.id)}
          </List.Description>
        </List.Content>
      </List.Item>
    ));
  }

  disableContainer() {
    const {
      subscriptions, currentSubscription,
    } = this.props;
    const { enableContainer, isUpdatingSubscription } = this.state;
    const check1 = subscriptions.subscriptionModel.total_count === 0;
    const check2 = _.get(currentSubscription, 'status', 'Cancelled') === 'Cancelled';
    const check = isUpdatingSubscription || check1 || check2;
    if (check && !enableContainer) return <Container className="element--full-height" />;

    return null;
  }

  productsContainer = () => {
    const {
      subscriptions, currentSubscription, products
    } = this.props;
    const { enableContainer, isUpdatingSubscription } = this.state;
    if (!enableContainer) return null;
    let flowType = '';
    if (_.get(subscriptions, 'subscriptionModel.total_count') === 0) flowType = 'createSubscription';
    if (_.get(currentSubscription, 'status') === 'Cancelled') flowType = 'createSubscription';
    if (isUpdatingSubscription) flowType = 'updateSubscription';

    if (flowType !== '') {
      return (
        <ProductContainer
          flowType={flowType}
          productType="BASE"
          products={products}
          activeProduct={this.state.activeProduct || getFrontendConfig('primaryProduct.BASE')}
          handleProductOnClick={this.handleProductClick}
          currentSubscription={currentSubscription}
          isUpdatingSubscription={isUpdatingSubscription}
        />
      );
    }

    return null;
  };

  loaderActive() {
    const {
      products, subscribeAccount
    } = this.props;
    const { enableContainer, } = this.state;
    if (!enableContainer) return true;
    if (_.isEmpty(products)) return true;
    if (subscribeAccount.id === null) return true;

    return false;
  }

  loaderMessage() {
    const {
      products, subscribeAccount
    } = this.props;

    if (!products || _.get(products, 'length', -1) === 0) return 'Checking Products';
    if (!subscribeAccount.id) return 'Checking Account';
    return 'Checking Subscription';
  }

  render() {
    const { isCancelSubscriptionDialogOpen } = this.state;

    return (
      <SimpleLoader active={!!this.loaderActive()} message={`${this.loaderMessage()}`}>
        {this.disableContainer()}
        {this.productsContainer()}
        <Confirm
          open={isCancelSubscriptionDialogOpen}
          onCancel={this.onSubscriptionCancelAbort}
          onConfirm={this.onSubscriptionCancelConfirm}
          content="Are you sure? This action cannot be undone"
          header="Cancel Subscription"
        />
      </SimpleLoader>
    );
  }
}

const makeMapStateToProps = () => {
  const currentSubscription = makeCurrentSubscriptionState();

  return (state, props) => ({
    subscriptions: state.subscriptions,
    subscribeAccount: state.subscribeAccount,
    products: state.products,
    currentSubscription: currentSubscription(state.subscriptions.subscriptionModel),
  });
};

const mapDispatchToProps = (dispatch) => ({
  createSubscriptionAddBaseProductProps: (selectedProduct) => dispatch(createSubscriptionAddBaseProduct(selectedProduct)),
  getProductsByIdProps: () => dispatch(getProductsById()),
});

export default connect(makeMapStateToProps(), mapDispatchToProps)(SubscriptionContainer);
