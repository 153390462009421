/* eslint-disable class-methods-use-this */
import React from 'react';
import {
  Segment,
  Grid,
  Header,
} from 'semantic-ui-react';

class MarketingComponent extends React.Component {
  render() {
    return (
      <Segment style={{ padding: '8em 0em' }} vertical>
        <Grid container stackable verticalAlign="middle">
          <Grid.Row>
            <Grid.Column width={8}>
              <Header as="h3" style={{ fontSize: '2em' }}>
                Temporary Landing Page
              </Header>
              <p style={{ fontSize: '1.33em' }}>
                Please click log in to proceed.
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

export default MarketingComponent;
