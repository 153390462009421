import React from 'react';
import { Container } from 'semantic-ui-react';

import MarketingTopNav from '../components/MarketingTopNav';
import ModalManager from '../containers/modals/ModalManager';

class DesktopLayout extends React.Component {
  state = { fixedMenuVisibility: false };

  hideFixedMenu = () => this.setState({ fixedMenuVisibility: false });

  showFixedMenu = () => this.setState({ fixedMenuVisibility: true });

  render() {
    const { children, disabled } = this.props;
    const { fixedMenuVisibility } = this.state;

    return (
      <Container>
        <MarketingTopNav isFixedMenuVisible={fixedMenuVisibility} disabled={disabled} />
        <ModalManager />
        {children}
      </Container>

    );
  }
}

export default DesktopLayout;
