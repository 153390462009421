// This file will contain constants that will be moved to
// config files once that framework has been implemented
// because we are not letting Subscribe handle the translations
// and we will make the site configurable via the TDB files
// so that we can support multiple Subscribe subscription systems

import _ from 'lodash';

export const productNames = {
  RBD_GOLD: 'Gold Package',
  RBD_SILVER: 'Silver Package',
  RBD_BRONZE: 'Bronze Package',
  RBD_DVR: 'DVR',
  RBD_MULTI_SCREEN: 'Multi Screen',
};

export const productDescriptions = {
  RBD_GOLD: 'This is the Gold package description',
  RBD_SILVER: 'This is the Silver package description',
  RBD_BRONZE: 'This is the Bronze package description',
  RBD_DVR: 'Adds the ability to record live streams to cloud',
  RBD_MULTI_SCREEN: 'Adds the ability to support multiple simultaneous streams',
};

export const productTypes = {
  RBD_GOLD: 'BASE',
  RBD_SILVER: 'BASE',
  RBD_BRONZE: 'PURCHASE',
  RBD_DVR: 'ENHANCEMENT',
  RBD_MULTI_SCREEN: 'ENHANCEMENT',
};

export const productLogos = {
  RBD_GOLD: 'trophy-gold-icon.png',
  RBD_SILVER: 'trophy-silver-icon.png',
  RBD_BRONZE: 'trophy-bronze-icon.png',
};

export const productFeatures = {
  RBD_SILVER: ['Responsive Design', 'Text Customization', 'Color Customization', 'AWS Hosted'],
  RBD_GOLD: [
    'Responsive Design',
    'Text Customization',
    'Color Customization',
    'AWS Hosted',
    'Uses Machine Learning',
    'Skynet Compatible',
  ],
  RBD_BRONZE: ['Responsive Design', 'Text Customization', 'AWS Hosted'],
};

export const productSortOrder = {
  RBD_SILVER: 1,
  RBD_GOLD: 2,
  RBD_BRONZE: 3,
};

export const productSortOrderMobile = {
  RBD_GOLD: 1,
  RBD_SILVER: 2,
  RBD_BRONZE: 3,
};

export const getProductLogo = (productId) => _.get(productLogos, productId);

export const product = {
  productNames,
  productDescriptions,
  productTypes,
  productLogos,
  productFeatures,
  productSortOrder,
  productSortOrderMobile,
  getProductLogo,
};

export default product;
