// import _ from 'lodash';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { UserContext } from '../contexts/userContext';
import AuthService from '../services/authService';
import { getFrontendConfig } from '../utils/clientHelper';
import './App.scss';

const faviconUrl = getFrontendConfig('favicon');
const authService = new AuthService();

function getFaviconEl() {
  return document.getElementById('favicon');
}

class App extends Component {
  state = {
    theme: '',
    authService,
    isAuthenticated: authService.isAuthenticated,
  };

  render() {
    const { children } = this.props;
    const favicon = getFaviconEl();
    favicon.href = faviconUrl;

    return (
      <UserContext.Provider value={this.state}>
        <div className="core-layout--viewport">{children}</div>
      </UserContext.Provider>
    );
  }
}

export default withRouter(App);
