import React from 'react';
import ReactDOM from 'react-dom';
import { Router as BrowserRouter } from 'react-router';
import { Provider } from 'react-redux';
import App from './containers/App';
import store from './redux/configureStore';
import Routes from './routes';
import browserHistory from './history';
import ScrollToTop from './components/ScrollToTop';
import './containers/App.scss';
import './index.scss';

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter history={browserHistory}>
      <ScrollToTop />
      <App>
        <Routes />
      </App>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
