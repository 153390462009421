import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Segment } from 'semantic-ui-react';

import ProductDisplay from './ProductDisplay';

class SubscriptionDisplay extends React.Component {
  buildSummaryModel() {
    const createSubscriptionModel = _.get(this.props, 'createSubscriptionModel');
    const items = _.get(createSubscriptionModel, 'items');

    const displayProduct = _.get(_.find(items, { product: { productType: 'BASE' } }), 'product');

    return {
      displayProduct,
      enhancementProducts: [],
    };
  }

  render() {
    const { displayProduct } = this.buildSummaryModel();
    if (!displayProduct) return null;

    return (
      <Segment basic style={{ maxWidth: '400px' }}>
        <ProductDisplay displayProduct={displayProduct} />
      </Segment>
    );
  }
}

function mapStateToProps(store) {
  return {
    createSubscriptionModel: store.subscriptions.createSubscriptionModel,
  };
}

export default connect(mapStateToProps, null)(SubscriptionDisplay);
