import { combineReducers } from 'redux';

import products from './products/reducers';
import subscriptions from './subscriptions/reducers';
import subscribeAccount from './accounts/reducers';
import purchases from './purchases/reducers';
import modals from './modals/reducers';

export default combineReducers({
  products,
  subscriptions,
  subscribeAccount,
  purchases,
  modals,
});
