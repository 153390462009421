import _ from 'lodash';
import defaultProducts from '../clients/default/products';
import attmxccpocProducts from '../clients/attmxccpoc/products';

import {
  getMerchantConfig,
  getImageMerchant,
  importAllImagesMerchant,
  env,
  client,
} from '../clients/merchantConfig';

export const getImage = (data) => getImageMerchant(data);
export const importAllImages = (data) => importAllImagesMerchant(data);

const frontEndElement = (element) => {
  if (element === '') return 'frontend';
  return `frontend.${element}`;
};

export const getFrontendConfig = (element = '', defaultValue = false) => getMerchantConfig(frontEndElement(element, defaultValue));

export const formatCurrency = (_amount) => {
  const amount = _.toNumber(_amount);
  if (client === 'attmxccpoc') return `${getFrontendConfig('currencySymbol')}${amount.toFixed(2)}`;

  const currencyType = getFrontendConfig('currencyType');

  const formatStyle = (type) => {
    switch (type) {
      case 'en-US':
        return {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        };
      case 'es-MX':
        return {
          style: 'currency',
          currency: 'MXN',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        };
      default:
        return {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        };
    }
  };

  const formatter = new Intl.NumberFormat(currencyType, formatStyle(currencyType));
  return formatter.format(amount);
};

export const productHelper = () => {
  if (client === 'attmxccpoc') return attmxccpocProducts;
  return defaultProducts;
};

export const PropertyNameEnum = {
  LOGO: 'LOGO',
  NAME: 'NAME',
  DESCRIPTION: 'DESCRIPTION',
  NONE: 'NONE',
};

export const ProductTypeEnum = {
  BASE: 'BASE',
  ENHANCEMENT: 'ENHANCEMENT',
};

// move into each folder at some point
export const validateEmail = (email) => {
  if (email.trim() === '') return false;

  if (env === 'prd' && /^[a-zA-Z0-9.!#$%&'*/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) return true;

  if (env !== 'prd' && /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) return true;

  return false;
};

export const apiResponse = (statusCode, errorMessage, data = '') => ({
  statusCode,
  errorMessage,
  success: statusCode === 200,
  data,
});
