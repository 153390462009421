/* eslint-disable no-return-assign */
import _ from 'lodash';
import defaultPageHeader from './default/components/PageHeader';
import defaultPageFooter from './default/components/PageFooter';
import TermsAndConditionsDefault from './default/components/files/TermsAndConditions';
import TermsAndConditionsAttMxCC from './attmxccpoc/components/files/TermsAndConditions';

export const env = _.toLower(_.trim(_.get(process, 'env.REACT_APP_STOREFRONT_ENV', 'dev')));
export const client = _.toLower(_.trim(_.get(process, 'env.REACT_APP_STOREFRONT_CLIENT', 'attmxccpoc')));

export const importAllImagesMerchant = (context) => {
  // require.context allows webpack to dynamically import all possible modules in a given directory/regex match
  // you must provide LITERALS to `require.context` or it WON'T WORK because the modules are generated at COMPILE time.

  const imageContext = context || require.context('../clients/shared/components/images', false, /\.(png|jpe?g|svg)$/);
  const images = {};

  imageContext.keys().map((item) => (images[item.replace('./', '')] = imageContext(item)));

  return images;
};

export const getImageMerchant = (name) => _.get(importAllImagesMerchant(), name);

const merchantConfig = {
  default: {
    invoicePayNow: { emailSubject: '', emailBody: '', emailReply: '' },
    middleWare: { productPrefix: 'RBD', source: 'Rebar' },
    frontend: {
      favicon: getImageMerchant('favicon.png'),
      scenicBackground: getImageMerchant('scenic-background.jpg'),
      headerBackgroundImage: getImageMerchant('starry-night.jpg'),
      headerBackgroundColor: '#000000de',
      backgroundsDark: 'https://rebartechnology.com/assets/images/aliencon/logo.svg',
      homePage: { link: 'https://rebartechnology.com/', label: 'Home' },
      facebook: { enabled: false },
      twitter: { enabled: false },
      // TODO: [RT-1592] Storefront Deterine how to get Images and fonts to Build folder
      // TODO: [RT-1593] Storefront Depoyment of Code specfic to each client
      PageHeaderComponent: { enabled: false, component: defaultPageHeader },
      PageFooterComponent: { enabled: false, component: defaultPageFooter },
      TermsAndConditions: {
        Label: 'I agree to the ',
        Link: 'Terms And Conditions',
        Text: TermsAndConditionsDefault
      },
      MenuComponent: { enabled: false },
      MarketingComponent: { enabled: true, label: 'Marketing' },
      PurchaseContainer: { enabled: false, label: 'One Time' },
      SubscriptionContainer: { enabled: true, label: 'Subscription' },
      EnhancementContainer: { enabled: false, label: 'Add Ons' },
      InvoiceContainer: { enabled: false, label: 'Invoice' },
      accountPrefix: 'REBAR_',
      purchaseSuccess: { title: 'Thank you for your purchase!', description: 'Thank you for your purchase of this product. Hope you enjoy your purchase!' },
      purchaseFailure: { title: 'Failure to Purchase', description: 'Sorry, your purchase was not successful. Please try again.' },
      purchaseProcess: { title: 'Processing...', description: 'Hold tight while we process your request.' },
      primaryProduct: 'RBD_GOLD',
      currencySymbol: '$',
      urlPath: '',
      apiLock: {
        dev: false, stg: true, qa: true, prd: true
      },
      authService: {
        type: 'auth0',
        domain: 'rebartechnology.auth0.com',
        clientId: 'BDc96tZj9c6aMqeFQHJS19SvmGwhfHDC',
        signUp: false,
      },
      billingServer: {
        dev: 'api.staging.us-west.vindicia.com',
        stg: 'api.staging.us-west.vindicia.com',
        qa: 'api.staging.us-west.vindicia.com',
        prd: 'secure.vindicia.com',
      },
      billingApi: {
        dev: 'https://api.dev.client.rebartechnology.io',
        stg: 'https://api.stg.client.rebartechnology.io',
        qa: 'https://api.qa.client.rebartechnology.io',
        prd: 'https://api.client.rebartechnology.io',
      },
    }
  },
  attmxccpoc: {
    invoicePayNow: { emailSubject: '', emailBody: '', emailReply: '' },
    middleWare: { productPrefix: 'ATTMXCC', source: 'Subscribe' },
    frontend: {
      favicon: getImageMerchant('favicon.png'),
      scenicBackground: getImageMerchant('scenic-background.jpg'),
      headerBackgroundImage: getImageMerchant('starry-night.jpg'),
      headerBackgroundColor: '#000000de',
      homePage: { link: '/', label: 'Home' },
      afterPurchase: { link: '/subscription', label: 'Continue Shopping' },
      facebook: { enabled: false },
      twitter: { enabled: false },
      // TODO: [RT-1592] Storefront Deterine how to get Images and fonts to Build folder
      // TODO: [RT-1593] Storefront Depoyment of Code specfic to each client
      PageHeaderComponent: { enabled: false, component: defaultPageHeader },
      PageFooterComponent: { enabled: false, component: defaultPageFooter },
      TermsAndConditions: {
        Label: '',
        Link: 'Terms And Conditions',
        Text: TermsAndConditionsAttMxCC
      },
      MenuComponent: { enabled: false },
      MarketingComponent: { enabled: true, label: 'Landing' },
      PurchaseContainer: { enabled: false, label: 'One Time' },
      SubscriptionContainer: { enabled: true, label: 'Subscription' },
      EnhancementContainer: { enabled: false, label: 'Add Ons' },
      InvoiceContainer: { enabled: false, label: 'Invoice' },
      accountPrefix: 'ATTPOC_',
      purchaseSuccess: { title: 'Thank you for your purchase!', description: 'Thank you for your purchase of this product. Hope you enjoy your purchase!' },
      purchaseFailure: { title: 'Failure to Purchase', description: 'Sorry, your purchase was not successful. Please try again.' },
      purchaseProcess: { title: 'Processing...', description: 'Hold tight while we process your request.' },
      primaryProduct: { BASE: '', PURCHASE: '' },
      currencySymbol: 'MXN ',
      currencyType: 'es-Eu',
      urlPath: '',
      apiLock: {
        dev: false, stg: false, qa: false, prd: false
      },
      authService: {
        type: 'auth0',
        domain: 'rebartechnology.auth0.com',
        clientId: 'BDc96tZj9c6aMqeFQHJS19SvmGwhfHDC',
        signUp: false,
      },
      billingServer: {
        dev: 'api.staging.us-west.vindicia.com',
        stg: 'api.staging.us-west.vindicia.com',
        qa: 'api.staging.us-west.vindicia.com',
        prd: 'secure.vindicia.com',
      },
      billingApi: {
        dev: 'https://api.dev.attmxccpoc.rebartechnology.io',
        stg: 'https://api.stg.attmxccpoc.rebartechnology.io',
        qa: 'https://api.qa.attmxccpoc.rebartechnology.io',
        prd: 'https://api.attmxccpoc.rebartechnology.io',
      },
      vindicia: {
        authId: {
          dev: 'YXR0bXhjY19zdGFnX3BtdDozMnM4SDJkNQ==',
          stg: 'YXR0bXhjY19zdGFnX3BtdDozMnM4SDJkNQ==',
          qa: 'YXR0bXhjY19zdGFnX3BtdDozMnM4SDJkNQ==',
          prd: '',
        },
        server: {
          dev: 'secure.staging.us-west.vindicia.com',
          stg: 'secure.staging.us-west.vindicia.com',
          qa: 'secure.staging.us-west.vindicia.com',
          prd: 'secure.vindicia.com',
        },
        restServer: {
          dev: 'api.staging.us-west.vindicia.com',
          stg: 'api.staging.us-west.vindicia.com',
          qa: 'api.staging.us-west.vindicia.com',
          prd: 'api.vindicia.com',
        },
        hmac: {
          dev: '949glJVX2puursxXhUbqZSYXygg',
          stg: '949glJVX2puursxXhUbqZSYXygg',
          qa: '949glJVX2puursxXhUbqZSYXygg',
          prd: '',
        },
        currency: 'MXN',
        validate: '1',
        ignoreCvnPolicy: true,
        ignoreAvsPolicy: true,
        minChargebackProb: {
          dev: '100',
          stg: '100',
          qa: '100',
          prd: '100',
        },
      },
    }
  }
};

export const getMerchantConfig = (element, defaultValue = false) => {
  if (element === 'client') return _.get(merchantConfig, `${client}`);

  const clientElement = _.get(
    merchantConfig,
    `${client}.${element}.${env}`,
    _.get(merchantConfig, `${client}.${element}`, 'rt-no-element')
  );

  if (clientElement !== 'rt-no-element') return clientElement;

  return _.get(merchantConfig, `default.${element}.${env}`, _.get(merchantConfig, `default.${element}`, defaultValue));
};
