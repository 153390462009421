export const ACTION_TYPES = {
  SUBSCRIPTIONS_REQUEST: 'subscriptions/REQUEST',
  SUBSCRIPTIONS_SUCCESS: 'subscriptions/SUBSCRIPTIONS_SUCCESS',
  SUBSCRIPTIONS_FAILURE: 'subscriptions/SUBSCRIPTIONS_FAILURE',
  SUBSCRIPTIONS_DEFAULT: 'subscriptions/SUBSCRIPTIONS_DEFAULT',
  SUBSCRIPTIONS_CREATE_ADD_BASE_PRODUCT: 'subscriptions/create/ADD_BASE_PRODUCT',
  SUBSCRIPTIONS_CREATE_REMOVE_ENHANCEMENT_PRODUCT: 'subscriptions/create/REMOVE_ENHANCEMENT_PRODUCT',
  SUBSCRIPTIONS_CREATE_ADD_ENHANCEMENT_PRODUCT: 'subscriptions/create/ADD_ENHANCEMENT_PRODUCT',
  SUBSCRIPTIONS_CREATE_ADD_PAYMENT_METHOD: 'subscriptions/create/ADD_PAYMENT_METHOD',
  SUBSCRIPTIONS_CREATE_ADD_CAMPAIGN_CODE: 'subscriptions/create/ADD_CAMPAIGN_CODE',
  SUBSCRIPTIONS_CREATE_REMOVE_CAMPAIGN_CODE: 'subscriptions/create/REMOVE_CAMPAIGN_CODE',
  SUBSCRIPTIONS_CREATE_AUTO_BILL: 'subscriptions/create/CREATE_AUTO_BILL',
  SUBSCRIPTIONS_CANCEL_AUTO_BILL_REQUEST: 'subscriptions/cancel/CANCEL_REQUEST',
  SUBSCRIPTIONS_CANCEL_AUTO_BILL_SUCCESS: 'subscriptions/cancel/CANCEL_SUCCESS',
  SUBSCRIPTIONS_CANCEL_AUTO_BILL_FAILURE: 'subscriptions/cancel/CANCEL_FAILURE',
  SUBSCRIPTIONS_UPDATE_ADD_ENHANCEMENT_PRODUCT: 'subscriptions/update/ADD_ENHANCEMENT_PRODUCT',
  SUBSCRIPTIONS_UPDATE_REMOVE_ENHANCEMENT_PRODUCT: 'subscriptions/update/REMOVE_ENHANCEMENT_PRODUCT',
  SUBSCRIPTIONS_UPDATE_ADD_BASE_PRODUCT: 'subscriptions/update/ADD_BASE_PRODUCT',
  SUBSCRIPTIONS_UPDATE_AUTO_BILL: 'subscriptions/update/UPDATE_AUTO_BILL',
};

export const subscriptionsRequest = () => ({
  type: ACTION_TYPES.SUBSCRIPTIONS_REQUEST,
});

export const subscriptionsSuccess = (results) => ({
  type: ACTION_TYPES.SUBSCRIPTIONS_SUCCESS,
  payload: results,
});

export const subscriptionsFailure = (error) => ({
  type: ACTION_TYPES.SUBSCRIPTIONS_FAILURE,
  payload: error,
});

export const subscriptionsDefault = () => ({
  type: ACTION_TYPES.SUBSCRIPTIONS_DEFAULT,
  payload: { status: 200, data: [], total_count: 0 },
});

export const createSubscriptionCreateAutoBill = (results) => ({
  type: ACTION_TYPES.SUBSCRIPTIONS_CREATE_AUTO_BILL,
  payload: results,
});

export const createSubscriptionAddPaymentMethod = (paymentId) => ({
  type: ACTION_TYPES.SUBSCRIPTIONS_CREATE_ADD_PAYMENT_METHOD,
  payload: paymentId,
});

export const createSubscriptionAddCampaignCode = ({ campaignCode, discountTotal, nextTotal }) => ({
  type: ACTION_TYPES.SUBSCRIPTIONS_CREATE_ADD_CAMPAIGN_CODE,
  payload: {
    campaignCode,
    discountTotal,
    nextTotal,
  },
});

export const createSubscriptionAddEnhancementRequest = ({ selectedProductId, products, accountId }) => ({
  type: ACTION_TYPES.SUBSCRIPTIONS_CREATE_ADD_ENHANCEMENT_PRODUCT,
  payload: { selectedProductId, products, accountId },
});

export const createSubscriptionRemoveEnhancementRequest = ({ selectedProductId, products, accountId }) => ({
  type: ACTION_TYPES.SUBSCRIPTIONS_CREATE_REMOVE_ENHANCEMENT_PRODUCT,
  payload: { selectedProductId, products, accountId },
});

export const createSubscriptionRemoveCampaignCode = () => ({
  type: ACTION_TYPES.SUBSCRIPTIONS_CREATE_REMOVE_CAMPAIGN_CODE,
});

export const updateSubscriptionRemoveEnhancementRequest = ({ selectedProductId, products, accountId }) => ({
  type: ACTION_TYPES.SUBSCRIPTIONS_UPDATE_REMOVE_ENHANCEMENT_PRODUCT,
  payload: { selectedProductId, products, accountId },
});

export const updateSubscriptionAddEnhancementRequest = ({ selectedProductId, products, accountId }) => ({
  type: ACTION_TYPES.SUBSCRIPTIONS_UPDATE_ADD_ENHANCEMENT_PRODUCT,
  payload: { selectedProductId, products, accountId },
});

export const updateSubscriptionRemoveEnhancement = ({
  subscriptionItem, selectedProductId, products, accountId
}) => ({
  type: ACTION_TYPES.SUBSCRIPTIONS_UPDATE_REMOVE_ENHANCEMENT_PRODUCT,
  payload: {
    subscriptionItem,
    selectedProductId,
    products,
    accountId,
  },
});

export const updateSubscriptionAddEnhancement = ({
  subscriptionItem, selectedProductId, products, accountId
}) => ({
  type: ACTION_TYPES.SUBSCRIPTIONS_UPDATE_ADD_ENHANCEMENT_PRODUCT,
  payload: {
    subscriptionItem,
    selectedProductId,
    products,
    accountId,
  },
});

export const updateSubscriptionWithBaseProduct = ({
  selectedProductId,
  currentBaseSubscriptionItem,
  products,
  accountId,
}) => ({
  type: ACTION_TYPES.SUBSCRIPTIONS_UPDATE_ADD_BASE_PRODUCT,
  payload: {
    selectedProductId,
    currentBaseSubscriptionItem,
    products,
    accountId,
  },
});

export const createSubscriptionWithBaseProduct = ({ selectedProduct, accountId, email }) => ({
  type: ACTION_TYPES.SUBSCRIPTIONS_CREATE_ADD_BASE_PRODUCT,
  payload: { selectedProduct, accountId, email },
});

export const cancelSubscriptionRequest = () => ({
  type: ACTION_TYPES.SUBSCRIPTIONS_CANCEL_AUTO_BILL_REQUEST,
});

export const cancelSubscriptionSuccess = (results) => ({
  type: ACTION_TYPES.SUBSCRIPTIONS_CANCEL_AUTO_BILL_SUCCESS,
  payload: results,
});

export const cancelSubscriptionFailure = (results) => ({
  type: ACTION_TYPES.SUBSCRIPTIONS_CANCEL_AUTO_BILL_FAILURE,
  payload: results,
});

export const updateSubscriptionUpdateAutoBill = (results) => ({
  type: ACTION_TYPES.SUBSCRIPTIONS_UPDATE_AUTO_BILL,
  payload: results,
});
